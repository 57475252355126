<template>
  <div class="relative">
    <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor hidden md:block"
      style="height: 304px; z-index: 0">
      <div class="container max-w-[1200px] relative z-10 h-full flex items-center mb-10 pb-10">
        <h3 class="text-[48px] md:text-[64px] text-white text-left">Nosso Blog</h3>
      </div>
    </div>

    <!-- Principal Container para o Artigo e Tags -->
    <div
      class="container max-w-[1200px] flex flex-col md:flex-row justify-between py-12 mt-[-60px] relative z-20 bg-white">
      <!-- Seção de Artigo -->
      <div class="w-full md:w-[70%] mt-10 md:mt-0 px-8">
        <div class="flex flex-col">
          <h1 class="text-[32px] font-bold text-[#343434]">{{ artigo.titulo }} </h1>
          <span class="text-[16px] mb-4 text-[#4A4A4A]">Publicado em {{ formatDate(artigo.created_at) }}</span>
        </div>
        <div class="flex justify-center">
          <img v-if="artigo.image" :src="artigo.image" class="img-fluid-article rounded-[15px]" />
          <img v-else src="../../img/tinta.jpg" class="img-fluid-article" />
        </div>

        <div class="text-formated mt-4 text-[#383838]">
          <p v-html="artigo.conteudo"></p>
        </div>

        <div class="flex justify-start items-center py-4">
          <a :href="whatsappShareLink" target="_blank" class="cursor-pointer">
            <img src="../../../resources/img/whatsapp-blog-icon.png" alt="Compartilhar no WhatsApp" class="w-8 h-8 mr-2" />
          </a>
          <a :href="facebookShareLink" target="_blank" class="cursor-pointer">
            <img src="../../../resources/img/facebook-blog-icon.png" alt="Compartilhar no Facebook" class="w-8 h-8 mr-2" />
          </a>
        </div>

        <hr class="w-full" />
        <div class="flex items-center flex-wrap gap-2.5 py-3">
          <h1 class="text-[16px]">Tags: </h1>

          <span v-for="(tags, index) in artigo.tags" :key="index" class="bg-black/10 cursor-pointer p-2"
            @click="redirectBlogFilterTags(tags.tag.id)">
            {{ tags.tag.nome }}
          </span>
        </div>

        <hr class="w-full" />

        <div v-if="relatedPosts.length > 0" class="grid grid-cols-1 md:grid-cols-2 gap-x-4 mt-4 w-full">
          <div v-for="post in relatedPosts" :key="post.id" class="flex flex-col items-start w-full">
            <router-link :to="{ name: 'blog.artigo', params: { slug: post.slug } }" class="block w-full">
              <img v-if="post.image" :src="post.image" class="w-full h-auto mb-4 rounded-[15px] object-cover"
                style="aspect-ratio: 16 / 9;" />
              <img v-else src="../../img/tinta.jpg" class="w-full h-auto mb-4 rounded-[15px] object-cover"
                style="aspect-ratio: 16 / 9;" />
            </router-link>

            <router-link :to="{ name: 'blog.artigo', params: { slug: post.slug } }" class="text-[20px] font-bold mb-4 md:mb-0">
              {{ post.titulo }}
            </router-link>
          </div>
        </div>

      </div>

      <div class="w-full md:w-[30%] mt-8 md:mt-0 px-8 py-4">
        <!-- <h2 class="text-[24px] font-bold mb-4">TAGS:</h2> -->
        <div class="flex flex-wrap">
          <span v-for="tag in uniqueTags" :key="tag.id"
            class="cursor-pointer text-[20px] mr-2 mb-2 bg-gray-200 px-1.5 py-1" @click="searchByTag(tag.id)">
            {{ tag.nome }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { createHead } from "@unhead/vue";

const artigo = ref({});
const route = useRoute();
const uniqueTags = ref([]);
const relatedPosts = ref([]);
const currentUrl = ref(window.location.href);
const head = createHead();

const title = computed(() => artigo.value.titulo || "Nosso Blog");
const description = computed(() => artigo.value.descricao || "Confira este artigo interessante em nosso blog.");
const image = computed(() => artigo.value.image || "URL_DA_IMAGEM_PADRAO");

const redirectBlogFilterTags = (tagId) => {
  window.location.href = `/blog?tag=${tagId}`;
};

const whatsappShareLink = computed(() => {
  const baseUrl = window.location.href;
  const message = `Confira este artigo: *${artigo.value.titulo}*\n\n${artigo.value.descricao}\n\nLeia mais em: ${baseUrl}`;
  return `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
});

const facebookShareLink = computed(() => {
  return `https://www.facebook.com/sharer/sharer.php?u=${currentUrl.value}`;
});

const carregarArtigo = async () => {
  const slug = route.params.slug;
  try {
    const response = await axios.get(`/api/blog/${slug}`);
    artigo.value = response.data;
    currentUrl.value = window.location.href;
    injectHeadTags();
    window.scrollTo(0, 0); // Scroll para o topo ao carregar o artigo
  } catch (error) {
    console.log(error);
  }
};

const carregarTodasTags = async () => {
  try {
    const response = await axios.get("/api/tags/all");
    uniqueTags.value = response.data;
  } catch (error) {
    console.log(error);
  }
};

const formatDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString("pt-BR", options);
};

const injectHeadTags = async () => {
  const headEntry = {
    title: title.value,
    meta: [
      { property: "og:type", content: "article" },
      { property: "og:title", content: title.value },
      { property: "og:description", content: description.value },
      { property: "og:image", content: image.value },
      { property: "og:url", content: currentUrl.value },
    ],
  };
  head.push(headEntry);
  const tags = await head.resolveTags();
  tags.forEach((tag) => {
    const element = document.createElement(tag.tag);
    if (tag.props) {
      Object.keys(tag.props).forEach((key) => element.setAttribute(key, tag.props[key]));
    }
    if (tag.textContent) {
      element.textContent = tag.textContent;
    }
    document.head.appendChild(element);
  });
};

const searchByTag = (tagId) => {
  window.location.href = `/blog?tag=${tagId}`;
};

const carregarPostagensRelacionadas = async () => {
  try {
    const response = await axios.get(`/api/blog/${route.params.slug}/related-posts`);
    relatedPosts.value = response.data;
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  carregarArtigo();
  carregarTodasTags();
  carregarPostagensRelacionadas();
});

watch(() => route.params.slug, () => {
  carregarArtigo();
  carregarPostagensRelacionadas();
});
</script>

<style>
.img-fluid-article {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.text-formated p {
  font-size: 16px;
  line-height: 1.6rem;
}

.text-formated h1 {
  font-size: 32px;
  line-height: 2.5rem;
}

.text-formated h2 {
  font-size: 24px;
  line-height: 2rem;
}

.text-formated h3 {
  font-size: 18px;
  line-height: 1.8rem;
}

.container {
  padding-bottom: 3rem;
}
</style>
