<template>
  <div id="header" class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor" style="height: 304px;">
    <div class="container max-w-[1200px] relative z-10 p-0">
      <h3 class="text-[48px] md:text-[64px] text-white text-left px-3 md:px-0">Sobre a Inkor</h3>
    </div>
  </div>
  <div class="flex md:rounded-none rounded-b-[30px] text-[14px] md:px-4 pt-10">
    <div class="container max-w-[1200px] py-0">
      <p>
        A Inkor Tintas e Argamassas fundada em 05 de fevereiro de 2004, vem buscando aprimoramentos contínuos na
        qualidade de seus produtos, onde temos a oportunidade de oferecer a nossos clientes produtos certificados de
        alta qualidade, fácil aplicação e excelente durabilidade.
        <br>
        <br>
        Somos uma empresa genuinamente brasileira que atua na produção de Tintas destinadas ao setor imobiliário,
        Argamassas e Impermeabilizantes. Temos o propósito de oferecer a nossos clientes não apenas o produto, mas
        valores, ou seja, produtos que a agregam qualidade e durabilidade aliados a praticidade de aplicação embelezando
        assim as construções com garantia de excelência pela qualidade.
        <br>
        <br>
        Para tal, a INKOR possui um parque fabril em uma área de aproximadamente 100.000 m2, sendo aproximadamente
        15.000 m2 de área construída, com capacidade de produção instalada de 30.000 toneladas/mês. Contamos com duas
        unidades fabris sendo uma nossa matriz que está localizada em Imbituba (SC) e num posicionamento estratégico da
        logística de distribuição contamos também com uma unidade fabril em Osório (RS).
        <br>
        <br>
        Quando falamos em empreendedorismo, subentende-se uma ideia que deu certo. A INKOR é um empreendimento de
        sucesso, pelo fato de visar o lucro como consequência. Sendo o foco no cliente, estamos sempre investindo em
        inovações tecnológicas e na capacitação de nossos colaboradores, mantemos fortes parcerias com nossos
        fornecedores, promovemos ações sociais e em toda tomada de decisão assumimos um compromisso com o meio ambiente,
        preservando o equilíbrio do ecossistema.
      </p>
    </div>
  </div>
  <!-- Seção exibida apenas em desktop (md e superior) -->
  <div class="hidden md:block py-10">
    <div class="container max-w-[1200px] mx-auto">
      <div class="flex flex-wrap gap-4">
        <div v-for="(item, index) in items" :key="index"
          class="flex flex-col text-white bg-[#011E41] flex-shrink-0 w-full sm:w-[calc(50%-1rem)] md:w-[calc(33.3333%-1rem)] h-[438px] rounded-3xl shadow-sm">
          <img class="rounded-lg w-full h-64 object-cover" :src="item.image" :alt="item.image">
          <div class="flex flex-col px-4 mt-6 w-full">
            <span class="text-3xl font-black">{{ item.year }}</span>
            <span class="break-words overflow-hidden">{{ item.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Seção exibida apenas em mobile (menos que md) -->
  <div class="block md:hidden py-4">
    <div id="nossa-historia" class="max-w-[1200px] mx-auto container relative p-0">
      <div class="relative">
        <div @click="prev"
          class="hidden md:block cursor-pointer absolute left-0 top-1/2 transform -translate-y-1/2 bg-white/50 rounded-full h-16 w-16 text-white px-4 py-2">
          <div class="mt-2">
            <img src="../../img/arrow-left.svg" alt="">
          </div>
        </div>

        <div ref="carousel" class="flex flex-row justify-start overflow-x-scroll h-96 no-scrollbar gap-4">
          <div v-for="(item, index) in items" :key="index"
            class="flex flex-col bg-[#011E41] text-white inline-block flex-shrink-0 w-96 h-96 rounded-3xl shadow-sm">
            <img class="rounded-lg h-64" :src="item.image" :alt="item.image">
            <div class="flex flex-col px-4 mt-2 w-full">
              <span class="text-3xl font-black">{{ item.year }}</span>
              <span class="break-words overflow-hidden">{{ item.description }}</span>
            </div>
          </div>
        </div>

        <div @click="next"
          class="hidden md:block cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2 bg-white/50 rounded-full h-16 w-16 text-white px-4 py-2">
          <div class="mt-2">
            <img src="../../img/arrow-right.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>




  <div>
    <div class="flex bg-inkor relative z-10 py-16">
      <div class="container max-w-[1200px] flex-col flex md:flex md:flex-row">
        <div class="flex items-center w-full">
          <div class="flex flex-col mt-20 md:mt-0">
            <h3 class="text-[36px] md:text-[64px] text-white text-left py-4 md:pb-12">Nossos Pilares</h3>

            <div class="flex flex-col md:flex-row text-white gap-4 md:gap-0">
              <div class="flex flex-col md:w-1/3 md:mr-10">
                <div class="flex items-start">
                  <!-- <span class="text-[48px] md:text-[64px] font-bold mr-4 flex-shrink-0 self-start">1</span> -->
                  <img class="mr-3 mt-3" src="../../img/1.svg" alt="">
                  <div class="flex flex-col">
                    <span class="text-[24px] md:text-4xl text-light">Acessíveis</span>
                    <span class="font-light">Estamos sempre disponíveis e prontos para atender as necessidades dos
                      nossos clientes.</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-col md:w-1/3 md:mr-10">
                <div class="flex items-start">
                  <!-- <span class="text-[48px] md:text-[64px] font-bold mr-4 flex-shrink-0 self-start">2</span> -->
                  <img class="mr-3 mt-3" src="../../img/2.svg" alt="">

                  <div class="flex flex-col">
                    <span class="text-[24px] md:text-4xl no-margin">Simples</span>
                    <span class="font-light">Tornamos todos os processos e interações fáceis de entender e sem
                      complicações.</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-col md:w-1/3 md:mr-10">
                <div class="flex items-start">
                  <!-- <span class="text-[48px] md:text-[64px] font-bold mr-4 flex-shrink-0 self-start">2</span> -->
                  <img class="mr-3 mt-3" src="../../img/3.svg" alt="">

                  <div class="flex flex-col">
                    <span class="text-[24px] md:text-4xl no-margin">Resolutivos</span>
                    <span class="font-light">Nos dedicamos a resolver problemas rapidamente e de maneira eficiente,
                      garantindo satisfação.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <section class="relative h-auto w-full" style="margin-top: 25rem;">
    <div id="nossa-historia" class="max-w-[1200px] absolute left-1/2 z-20 -translate-x-1/2 top-0 mx-auto container -translate-y-80">
      <div class="relative">
        <div @click="prev"
          class="hidden md:block cursor-pointer absolute left-0 top-1/2 transform -translate-y-1/2 bg-white/50 rounded-full h-16 w-16 text-white px-4 py-2">
          <div class="mt-2">
            <img src="../../img/arrow-left.svg" alt="">
          </div>
        </div>

        <div ref="carousel" class="flex flex-row justify-start overflow-x-scroll h-96 no-scrollbar gap-4">
          <div v-for="(item, index) in items" :key="index"
            class="flex flex-col bg-white inline-block flex-shrink-0 w-96 h-96 rounded-3xl shadow-sm">
            <img class="rounded-lg" :src="item.image" :alt="item.image">
            <div class="flex flex-col px-4 mt-2 w-full">
              <span class="text-3xl font-black">{{ item.year }}</span>
              <span class="break-words overflow-hidden">{{ item.description }}</span>
            </div>
          </div>
        </div>



        <div @click="next"
          class="hidden md:block cursor-pointer absolute right-0 top-1/2 transform -translate-y-1/2 bg-white/50 rounded-full h-16 w-16 text-white px-4 py-2">
          <div class="mt-2">
            <img src="../../img/arrow-right.svg" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="flex bg-inkor relative z-10" style="height: 600px;">
      <div class="container max-w-[1200px] flex-col flex md:flex md:flex-row">
        <div class="flex items-center w-4/5">
          <div class="flex flex-col mt-20 md:mt-0">
            <h3 class="text-[36px] md:text-[64px] text-white text-left py-4 md:pb-12 font-bold">Nossos Pilares</h3>
            <div class="flex flex-col md:flex-row text-white gap-4 md:gap-0">
              <div class="flex flex-col md:w-2/6 md:mr-10">
                <span class="text-[24px] md:text-4xl text-light">Acessíveis</span>
                <span class="font-light">Estamos sempre disponíveis e prontos para atender as necessidades dos nossos
                  clientes.</span>
              </div>
              <div class="flex flex-col md:w-2/6 md:mr-10">
                <span class="text-[24px] md:text-4xl">Simples</span>
                <span class="font-light">Tornamos todos os processos e interações fáceis de entender e sem
                  complicações.</span>
              </div>
              <div class="flex flex-col md:w-2/6 md:mr-10">
                <span class="text-[24px] md:text-4xl">Resolutivos</span>
                <span class="font-light">Nos dedicamos a resolver problemas rapidamente e de maneira eficiente,
                  garantindo satisfação.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <div>
  </div>

  <div class="bg-white container max-w-[1200px] flex flex-col py-10">
    <div>
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col md:w-1/2 md:mr-10">
          <span class="text-4xl">Missão</span>
          <span>Participar da realização dos sonhos dos nossos clientes, fornecendo produtos que proporcionem beleza,
            qualidade, conforto e durabilidade nas obras.</span>
        </div>
        <div class="flex flex-col md:w-1/2 mt-4 md:mt-0">
          <span class="text-4xl">Visão</span>
          <span>A marca tornar-se referência no setor da construção civil, nos segmentos de argamassas colantes,
            impermeabilizantes, tintas e aditivos.</span>
        </div>
      </div>
      <div class="flex flex-col py-4">
        <span class="text-4xl">Valores</span>
        <span>Trabalho em equipe: Com o trabalho em equipe, reunimos o melhor do conhecimento e habilidades para
          melhorar
          constantemente os nossos produtos e beneficiar os nossos clientes. Respeito: Tratar todos com dignidade e
          valorizar experiências, culturas e opiniões. Qualidade: Oferecer qualidade em nossos produtos, visando atender
          às exigências e expectativas do mercado. Foco no mercado: Orientar ações e criar produtos para atender aos
          anseios do mercado. Ética nos relacionamentos: Ter comportamento ético nos relacionamentos.</span>
      </div>
    </div>
  </div>

  <div id="sustentabilidade">
    <div class="flex items-center bg-inkor" style="border-radius: 0px;">
      <div class="px-3 md:px-0 container max-w-[1200px] relative z-10 p-0">
        <div class="py-10">
        <h3 class="text-[48px] md:text-6xl text-white text-left pb-8">Certificações</h3>
        <div class="grid grid-cols-2 md:grid-cols-4 text-white">
          <div class="w-3/4 md:w-2/4 p-2">
            <img src="../../img/iso-9001.png" alt="">
            <div class="flex flex-col">
              <span class="text-lg mt-3">ISO 9001</span>
              <span class="text-sm">Início da operação no Rio Grande do Sul, na cidade de Osório, RS.</span>
            </div>
          </div>
          <div class="w-3/4 md:w-2/4 p-2">
            <img src="../../img/iso-14001.png" alt="">
            <div class="flex flex-col">
              <span class="text-lg mt-3">IS0 14001</span>
              <span class="text-sm">Início da operação no Rio Grande do Sul, na cidade de Osório, RS.</span>
            </div>
          </div>
          <div class="w-3/4 md:w-2/4 p-2">
            <img src="../../img/iso-45001.png" alt="">
            <div class="flex flex-col">
              <span class="text-lg mt-3">ISO 45001</span>
              <span class="text-sm">Início da operação no Rio Grande do Sul, na cidade de Osório, RS.</span>
            </div>
          </div>
          <div class="w-3/4 md:w-2/4 p-2">
            <img src="../../img/selo-verde.png" alt="">
            <div class="flex flex-col">
              <span class="text-lg mt-3">Selo Verde</span>
              <span class="text-sm">Início da operação no Rio Grande do Sul, na cidade de Osório, RS.</span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

  <!-- <div class="flex items-center w-full justify-end right-0" style="height: 813px;">
      <div class="container flex justify-between w-full h-full" style="background-color: #A6A6A6;">
        <div class="flex flex-col justify-center">
          <h3 class="text-[36px] md:text-[64px] text-white text-left py-4 md:pb-12 font-light">Estamos cada vez <br>
            mais pertinho de <br> você</h3>
          <span class="text-white text-xl">Estamos presentes em várias partes do Brasil em locais <br> estratégicos para
            atender você.</span>
        </div>
        <img src="../../img/mapa-filiais.svg" alt="">
      </div>
  </div> -->


  <!-- <section class="mt-5 mb-5" id="breadcrumb">
    <div class="container">
      <a href="/">home</a>
      &gt;
      <a href="/sobre-nos">sobre</a>
    </div>
  </section> -->
  <!-- <section id="sobre-page" class="mt-5">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3">
          <h3 class="bold">Sobre nós</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="page mb-5" title="Page">
            <div class="layoutArea">
              <div class="column">
                <p>
                  <span style="font-size: 12pt">
                    {{ Institucional.sobreNos.texto }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 bg-gray-light pb-2">
            <ColoredBar />
            <h4 class="bold text-center mt-2">Missão</h4>
            <p class="px-5">
              {{ Institucional.mvv.missao }}
            </p>
          </div>

          <div class="col-sm-12 bg-gray-light mt-5 pb-2">
            <ColoredBar />
            <h4 class="bold text-center mt-2">Visão</h4>
            <p class="px-5">
              {{ Institucional.mvv.visao }}
            </p>
          </div>

          <div class="col-sm-12 bg-gray-light mt-5 pb-2">
            <ColoredBar />
            <h4 class="bold text-center mt-2">Valores</h4>
            <p class="px-5">
              {{ Institucional.mvv.valores }}
            </p>
          </div>

          <div class="col-sm-12 bg-red mt-5 pb-2">
            <ColoredBar />
            <h4 class="bold text-center -white mt-2">A nossa Marca</h4>
          </div>
          <div class="col-sm-12 mt-3">
            <p>
              Todos os materiais desenvolvidos com a marca Inkor, devem ser produzidos de acordo com o Manual da Marca e
              enviados para
              <strong>marketing@inkor.com.br</strong>
              para serem aprovados.
            </p>
          </div>

          <div class="col-sm-12 mt-5">
            <h5 class="bold">Downloads</h5>
            <ColoredBar class="mb-4 pb-0" />
            <h6 class="bold">Logomarca</h6>
            <a href="/documents/PNG.rar" target="_blank" class="me-2">
              <img src="../../img/PNG.svg" height="50" />
            </a>
            <a href="/documents/SVG.rar" target="_blank" class="me-2">
              <img src="../../img/SVG.svg" height="50" />
            </a>
            <a href="/documents/PDF.rar" target="_blank" class="me-2">
              <img src="../../img/PDF.svg" height="50" />
            </a>
            <h6 class="bold mt-5">Manual da Marca</h6>
            <a href="/documents/manual-da-marca_inkor.pdf" target="_blank" class="me-2 mb-2">
              <img src="../../img/PDF.svg" class="mb-2" height="50" />
            </a>
            <h6 class="bold mt-5">Catálogo de Produtos</h6>
            <a href="/documents/Catalogo-2023-atualizado.pdf" target="_blank" class="me-2 mb-2">
              <img src="../../img/PDF.svg" class="mb-2" height="50" />
            </a>
            <h6 class="bold mt-5">Certificado de Regularidade (IBAMA)</h6>
            <a href="/documents/ESG.pdf" target="_blank" class="me-2 mb-2">
              <img src="../../img/PDF.svg" class="mb-2" height="50" />
            </a>
            <h6 class="bold mt-5">Certificado (Sistema de Gestão Ambiental)</h6>
            <a href="/documents/Certificado Sistema de Gestao Ambiental.pdf" target="_blank" class="me-2 mb-2">
              <img src="../../img/PDF.svg" class="mb-2" height="50" />
            </a>
            <h6 class="bold mt-5">Certificado (Sistemas de Gestão de Saúde e Segurança
              Ocupacional)</h6>
            <a href="/documents/Certificado Sistemas de Gestao de Saude e Seguranca Ocupacional.pdf" target="_blank"
              class="me-2 mb-2">
              <img src="../../img/PDF.svg" class="mb-2" height="50" />
            </a>
            <h6 class="bold mt-5">Certificado (Sistema de Gestão da Qualidade)</h6>
            <a href="/documents/Certificado Sistema de Gestao da Qualidade.pdf" target="_blank" class="me-2 mb-2">
              <img src="../../img/PDF.svg" class="mb-2" height="50" />
            </a>
          </div>
        </div>
        <div class="col-sm-6">
          <img :src="Institucional.sobreNos.imagem" class="img-fluid mb-5" />
          <ColoredBar />
          <div class="col bg-red py-2">
            <h4 class="-white text-center bold">Meio Ambiente</h4>
          </div>
          <div class="col mt-2">
            <p v-for="paragrafo in Institucional.meioAmbiente.texto" :key="paragrafo">
              {{ paragrafo }}
            </p>
          </div>

          <ColoredBar />
          <div class="col bg-red py-2 mb-4">
            <h4 class="-white text-center bold">Política do Sistema de Gestão Integrado
            </h4>
          </div>
          <div class="col mt-2 mb-5">
            <p>A INKOR, em sua unidade Industrial Matriz, situada na cidade de Imbituba, na Av. Levy Ramos Martins, s/n,
              no bairro de Nova Brasília, no distrito Industrial, lotes 12 e 13, tem como escopo de negócio: Projeto e
              Fabricação de produtos que ofereçam soluções para a área da construção civil, onde citamos as tintas
              imobiliárias, argamassas, impermeabilizantes e aditivos.</p>
            <p>Atuamos no segmento de soluções para a área da construção civil,
              desenvolvendo e produzindo produtos com a mais alta qualidade, oferecendo
              diversas opções que atendam às necessidades e expectativas dos nossos clientes e de
              todas as partes interessadas, tendo como princípios fundamentais ser:
            </p>

            <h5>SIMPLES, ACESSÍVEL E RESOLUTIVO.</h5>
            <p>
              <br />
              Com base nestes três pilares definimos pontos importantes em nossa política: Implementar e manter um
              Sistema
              de Gestão Integrado adequado e comprometido aos requisitos pertinentes às normas específicas e outras
              aplicáveis, bem como as legislações regulamentares aplicáveis ao nosso negócio.
              <br />
              <br />
              Relacionamento com clientes com foco em fidelizar e conquistar novos clientes com atendimento e
              venda de produtos de qualidade, atendendo aos compromissos acordados com eficiência,
              transparência e ética;
              <br />
              <br />
              Comprometimento com a proteção do meio ambiente, eliminação dos perigos e redução dos riscos
              ocupacionais.
              <br />
              <br />
              Reconhecemos a importância de todos os colaboradores, permitindo o engajamento, a consulta e a
              participação nos processos, bem como a capacitação por meio de treinamentos, transparência e
              ações de gestão motivadoras.
              <br />
              <br />
              Comprometimento com a melhoria contínua do Sistema de Gestão da Integrado.
            </p>
          </div>

          <Socials />
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
import MapaFiliais from '../components/mapa-inkor.vue';
import fabrica2006 from '../../img/fabrica-2006.png';
import fabrica2007 from '../../img/fabrica-2007.png';
import fabrica2012 from '../../img/fabrica-2012.png';
import fabrica2016 from '../../img/fabrica-2016.png';
import fabrica2021 from '../../img/fabrica-2021.png';
import fabrica2022 from '../../img/fabrica-2022.png';
import fabrica2023 from '../../img/fabrica-2023.png';



export default {
  components: {
    MapaFiliais,
  },
  data() {
    return {
      items: [
        { image: fabrica2006, year: '2004', description: 'Inicio das nossas atividades com a finalidade de atender às demandas de mercado da época.' },
        { image: fabrica2006, year: '2006', description: 'Incrementação da nossa linha de produtos lançando a divisão de tintas e ampliação da produção de argamassas.' },
        { image: fabrica2007, year: '2007', description: 'Início da operação no Rio Grande do Sul, na cidade de Osório, RS.' },
        { image: fabrica2012, year: '2012', description: 'Inauguração da unidade de Imbituba/SC, nossa matriz.' },
        { image: fabrica2016, year: '2016', description: 'Inauguração da nova unidade de Osório/RS às margens da BR 101, triplicando sua capacidade no estado gaúcho.' },
        { image: fabrica2016, year: '2018', description: 'Início do plano de expansão do Grupo Inkor através do projeto Blocel, e definição de novas unidades.' },
        { image: fabrica2021, year: '2021', description: 'Início da montagem nas unidades de Rio Claro/SP e Guaíra/PR' },
        { image: fabrica2022, year: '2022', description: 'Inauguração das unidades Rio Claro/SP e Guaíra/PR.' },
        { image: fabrica2023, year: '2023', description: 'Inauguração da Inplakor.' },
        { image: fabrica2023, year: '2024', description: 'Inauguração da unidade de Piên/PR.' },
      ],
      scrollPosition: 0,
      Institucional: {
        sobreNos: {
          imagem: null,
          texto: "",
        },
        meioAmbiente: {
          texto: "",
        },
        mvv: {
          missao: "",
          visao: "",
          valores: "",
        },
      },
    };
  },
  async created() {
    try {
      const responseInstitucional = await axios.get("/api/institucional");
      this.Institucional = responseInstitucional.data;
      this.Institucional.meioAmbiente.texto = this.Institucional.meioAmbiente.texto.split("\n");
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    next() {
      const carousel = this.$refs.carousel;
      const itemWidth = carousel.firstElementChild.offsetWidth + parseInt(getComputedStyle(carousel.firstElementChild).marginRight);
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      this.scrollPosition = Math.min(this.scrollPosition + itemWidth, maxScrollLeft);
      console.log('Next:', this.scrollPosition); // Log de depuração
      carousel.scrollTo({ left: this.scrollPosition, behavior: 'smooth' });
    },
    prev() {
      const carousel = this.$refs.carousel;
      const itemWidth = carousel.firstElementChild.offsetWidth + parseInt(getComputedStyle(carousel.firstElementChild).marginRight);
      this.scrollPosition = Math.max(this.scrollPosition - itemWidth, 0);
      console.log('Prev:', this.scrollPosition); // Log de depuração
      carousel.scrollTo({ left: this.scrollPosition, behavior: 'smooth' });
    }
  }
};
</script>

<style>
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.break-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1024px) {
  .overflow-x-scroll {
    overflow-x: auto;
  }
}
</style>
