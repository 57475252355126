<template>
    <section class="container flex items-center justify-center">
        <div class="md:flex md:justify-center  p-3 w-full h-auto">
            <div class="hidden md:block h-full bg-blue-500">
                <img src="../../img/image-inkor-news.png" alt="Imagem da Inkor News" class="w-full h-full object-cover md:hidden lg:block">
            </div>
            <div class="flex flex-col  text-white p-4  justify-center" style="background-color: #F5333F;">

                <div class="flex flex-col">
                    <span class="text-2xl mb-2" style="font-weight: 700;">Inkor News</span>
                    <span class="mb-4">Cadastre-se e receba novidades, tendências, ofertas e muito mais!</span>
                </div>

                <div class="flex flex-col gap-4">
                    <div class="md:flex md:flex-wrap lg:flex-wrap gap-3">
                        <div class="flex flex-col md:w-1/2">
                            <label>Nome</label>
                            <input class="bg-transparent border border-1 h-[43px] border-white px-2" type="text">
                        </div>

                        <div class="flex flex-col md:w-1/3">
                            <label>WhatsApp</label>
                            <input class="bg-transparent border border-1 h-[43px] border-white px-2" type="text">
                        </div>
                    </div>

                    <div class="flex flex-col md:w-1/2">
                        <label>E-mail</label>
                        <input class="bg-transparent border border-1 h-[43px] border-white px-2" type="text">
                    </div>

                    <div class="md:flex md:gap-4">
                        <div class="flex items-center mb-4 mb-0 gap-4">
                            <button
                                :class="{ 'bg-white text-[#F5333F]': isSquareClicked, 'bg-transparent text-white': !isSquareClicked }"
                                class="border border-white border-1 flex items-center justify-center p-0"
                                style="height: 30px; width: 50px;" @click="toggleSquareClick">
                                <span v-if="isSquareClicked">×</span>
                            </button>
                            <span>
                                Eu li e concordo com os
                                <a href="#" class="underline text-white">Termos de uso</a>
                                e
                                <a href="#" class="underline text-white">Políticas de privacidade</a>
                            </span>
                        </div>

                        <button class="bg-white w-[201px] h-[30px]" style="color: #F5333F;">Cadastrar</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InkorNews',
    data() {
        return {
            isSquareClicked: false,
        };
    },
    methods: {
        toggleSquareClick() {
            this.isSquareClicked = !this.isSquareClicked;
        },
    },
};
</script>