<template>
  <div
    class="tiny-slider"
    id="slider"
  ></div>
</template>

<script>
import { tns } from "tiny-slider";

export default {
  data() {
    return {
      banners: [],
    };
  },
  async created() {
    try {
      const responseBanner = await axios.get("/api/banners");
      this.banners = responseBanner.data;
      let carouselHtml = "";
      for (let banner of this.banners) {
        carouselHtml += `
          <div style="background-image: linear-gradient(to right, ${banner.cor_direita} , ${banner.cor_esquerda})">
            <div class="row pb-xs-2">
              <div class="col-sm-6 py-5 banner-img">
                <img src="${banner.imagem}" style="max-height: 240px;"/>
              </div>
              <div class="col-sm-6 py-5 banner-item">
                <h1>
                  ${banner.title}
                </h1>
                <h4 class="d-none d-sm-block" style="width: 70%">${banner.subtitle}</h4>
                <a href="${banner.link}" class="pt-5">
                  <span class="bt-banner">Saiba mais</span>
                </a>
              </div>
            </div>
          </div> `;
      }
      document.getElementById("slider").innerHTML = carouselHtml;
      tns({
        autoplay: true,
        autoplayButtonOutput: false,
        container: ".tiny-slider",
        controls: false,
        mouseDrag: true,
        nav: false,
        responsive: { 0: { items: 1 }, 600: { items: 1 }, 1000: { items: 1 } },
        slideBy: "page",
      });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
