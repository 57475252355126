<template>
  <div class="bg-slate-100">
    <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor"
      style="height: 304px; margin-bottom: 2rem;">
      <div class="container relative z-10">
        <h3 class="text-[48px] md:text-[64px] text-white text-left">Simuladores e<br /> calculadoras
          de <br /> consumo</h3>
      </div>
    </div>
    <div class="mx-auto max-w-7xl sm:px-6 pt-1 lg:px-8 ">
      <div class="relative isolate overflow-hidde px-6 mt-16 text-center sm:px-16">
        <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-black sm:text-4xl">Calculadora de
          <br />Consumo de rejuntes
        </h2>
        <p class="mx-auto mt-6 max-w-xl text-lg leading-6 text-gray-400">Utilize nossos simuladores e calculadoras de
          consumo para planejar sua obra de forma descomplicada com as soluções oferecidas pela Inkor.
        </p>
      </div>
    </div>
    <div class="pb-40 py-12">
      <div class="container md:w-[18cm] w-[8cm] bg-white">
        <div class="row mt-5">
          <div id="form-calcular" class="form-calcular mt-4 px-4">
            <div class="col-sm-12 mb-3">
              <ColoredBar class="pb-0" />
              <p class="py-3 bg-gray-light">
                <select v-model="produto" class="input_calculadora md:w-[5cm] w-[6.3cm]" placeholder="Selecione">
                  <option value="0">Selecione o Rejunte</option>
                  <option value="5">Hiper Plus Piscinas</option>
                  <option value="7">Juntakor</option>
                  <option value="14">Rejunte Acrílico</option>
                  <option value="18">Hiper Juntakor</option>
                  <option value="93">Rejunte Acrílico Bicomponente</option>
                  <option value="12">Resikor</option>
                </select>
              </p>
            </div>
            <div class="col-sm-12 mb-3">
              <ColoredBar class="pb-0" />
              <p class="px-2 py-2 bg-gray-light">
                Tamanho da peça (cm):
                <br />
                <input type="text" class="input_calculadora" pattern="^\d+(,\d{1,2cm})?$" required="" name="comp"
                  placeholder="Comprimento" style="width: 120px" v-model="comprimento" />
                x
                <input type="text" class="input_calculadora" pattern="^\d+(,\d{1,2cm})?$" required="" name="lar"
                  placeholder="Largura" style="width: 120px" v-model="largura" />
              </p>
            </div>
            <div class="col-sm-12 mb-3">
              <ColoredBar class="pb-0" />
              <p class="px-2 py-3 bg-gray-light">
                Largura da Fuga(mm):
                <input type="text" class="input_calculadora ms-2" pattern="^\d+(,\d{1,2mm})?$" required="" name="mqa"
                  placeholder="cm" style="width: 90px" v-model="largura_fuga" />
              </p>
            </div>
            <div class="col-sm-12 mb-3">
              <ColoredBar class="pb-0" />
              <p class="px-2 py-3 bg-gray-light">
                Área a ser aplicada(m²):
                <input type="text" class="input_calculadora ms-2" pattern="^\d+(,\d{1,2m²})?$" required="" name="mqa"
                  placeholder="m²" style="width: 90px" v-model="assentamento" />
              </p>
            </div>
            <div class="col-sm-12 mb-3">
              <ColoredBar class="pb-0" />
              <p class="py-3 bg-gray-light">
                <select v-model="profundidade" class="input_calculadora md:w-1/4">
                  <option value="0">Qual sua Peça?</option>
                  <option value="4">Pastilha (4mm)</option>
                  <option value="8">Pisos ou Porcelanato(8mm)</option>
                  <option value="10">Pedras naturais (10mm)</option>
                </select>
              </p>
            </div>
            <div class="col-sm-12 mb-3 text-left">
              <button class="bg-red flex items-center justify-center" type="submit" id="bt-submit" @click="calcular">
                <img src="../../img/contato-enviar.svg" class="me-3" />
                Calcular
              </button>
            </div>
            <div class="col-sm-12 mt-3 mb-3">
              <ColoredBar class="pb-0" />
              <p class="px-2 py-3 bg-gray-light">
                Você utilizará:
                <span id="resultado_calculadora">{{ total }} kg <span v-if="sacos">(aprox. {{ sacos }}
                    un.)</span></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      produto: "0",
      largura: "",
      total: "0.00",
      comprimento: "",
      profundidade: "0",
      largura_fuga: "",
      assentamento: "",
      sacos: "",
    };
  },
  methods: {
    calcular() {
      if (this.produto == 0) {
        this.$notify({
          title: "Por favor, selecione o rejunte!",
          type: 'error',
        });
      } else if (!this.comprimento || !this.largura) {
        this.$notify({
          title: "Por favor, informe o tamanho da peça!",
          type: 'error',
        });
      } else if (!this.largura_fuga) {
        this.$notify({
          title: "Por favor, informe a largura da fuga!",
          type: 'error',
        });
      } else if (!this.assentamento) {
        this.$notify({
          title: "Por favor, informe a área a ser aplicada!",
          type: 'error',
        });
      } else if (this.profundidade == 0) {
        this.$notify({
          title: "Por favor, selecione a peça!",
          type: 'error',
        });
      } else {
        const largura = parseFloat(this.largura);
        const comprimento = parseFloat(this.comprimento);
        const profundidade = parseFloat(this.profundidade);
        const largura_fuga = parseFloat(this.largura_fuga);
        const assentamento = parseFloat(this.assentamento);
        const total =
          (((largura * 10 + comprimento * 10) * profundidade * largura_fuga * this.valor) /
            (largura * comprimento * 100)) *
          assentamento;
        this.total = total.toFixed(2);

        if (this.produto == 5 || this.produto == 7) this.sacos = Math.ceil(this.total / 5);
        if (this.produto == 14 || this.produto == 18 || this.produto == 93) this.sacos = Math.ceil(this.total / 1);
        if (this.produto == 12) this.sacos = Math.ceil(this.total / 2.9);

      }
    },
  },
  computed: {
    valor() {
      if (this.produto == 0) return 0;
      if (this.produto == 14) return 1.58;
      if (this.produto == 5 || this.produto == 7 || this.produto == 18) return 1.75;
      if (this.produto == 93) return 1.8;
      if (this.produto == 12) return 2.54;
      return 0;
    },
  },
};
</script>
