<template>
  <section v-if="produto" id="produtos-page" class="mt-0">
    <div class="flex items-end justify-content-md-end md:rounded-none rounded-b-[30px] bg-produto-banner h-96 relative">
      <div
        class="container max-w-[1200px] flex flex-col-reverse md:flex-row items-center justify-center col-sm-8 py-2 text-white text-center-xs pb-4 w-full md:mb-0 mb-20">
        <!-- Conteúdo do Produto -->
        <div class="flex flex-col justify-start h-full md:text-left md:w-2/3 w-full order-2 md:order-1 mt-4 md:mt-0">
          <h3 class="text-[48px] md:text-[64px]">{{ produto.nome }}</h3>


        </div>

        <!-- Imagem do Produto -->
        <div class="md:w-1/3 w-full flex justify-center order-1 md:order-2 -mb-64">
          <img :src="produto.imagem" class="img-fluid img-produto md:mr-6 w-3/4 md:w-full"
            style="width: auto; height: auto;" />
        </div>
      </div>
    </div>
    <div class="container max-w-[1200px]">

      <div class="row mt-32">
        <div class="col-sm-4 py-2"></div>
        <div class="col-sm-12 col-md-8 py-5  md:-translate-y-[150px]">
          <div class="flex flex-row md:space-x-12 w-full  gap-2 md:justify-start justify-center">
            <div class="flex flex-col mb-4 md:mb-0">
              <h6 class="font-semibold">BOLETIM TÉCNICO</h6>
              <a :href="produto.boletim" target="_blank" class="flex gap-2 mt-2 items-center">
                <img src="../../img/download_icon.svg" class="h-6 w-6" />
                <span class="text-black">Acesse aqui</span>
              </a>
            </div>
            <div class="flex flex-col">
              <h6 class="font-semibold">FISPQ</h6>
              <a :href="produto.fispq" target="_blank" class="flex gap-2 mt-2 items-center">
                <img src="../../img/download_icon.svg" class="h-6 w-6" />
                <span class="text-black">Acesse aqui</span>
              </a>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <span class="text-muted" v-html="toHtml('descricao')"></span>
            <div v-if="produto.cores.length > 1">
              <span class="text-[16px] font-semibold text-[#343434]/75">Cores Disponíveis:</span>
              <div class="flex flex-row gap-2">
                <div v-for="colorObj in produto.cores" :key="colorObj.cor" :style="{ backgroundColor: colorObj.cor }"
                  class="w-8 h-8 rounded-full border border-black">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row md:-translate-y-[150px]">
        <div class="col-sm-12">
          <h1 class="text-12 mb-10" style="font-size: 32px;">Informações <br> <span
              style="color: #F5333F;">Técnicas</span></h1>
          <dl class="space-y-6 divide-y divide-slate-900/10">
            <div v-for="(tab, index) in tabs" :key="index" class="pt-6">
              <dt @click="toggle(index)" class="cursor-pointer flex justify-between text-left text-slate-900">
                <span class="text-base font-semibold leading-6">
                  {{ tab.title }}
                </span>
                <span class="ml-6 flex h-7 items-center">
                  <svg v-if="!isOpen[index]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                  </svg>
                </span>
              </dt>
              <dd v-if="isOpen[index]" class="mt-2 pr-12">
                <div class="text-left text-muted" v-html="tab.content"></div>
              </dd>
            </div>
          </dl>
          <div v-if="this.perguntas.length > 0">
            <h1 class="text-12 my-12" style="font-size: 32px;">Dúvidas <br> <span
                style="color: #F5333F;">Frequentes</span></h1>
            <dl class="space-y-6 divide-y divide-slate-900/10">
              <div v-for="(pergunta, index) in perguntas" :key="index" class="pt-6">
                <dt @click="toggle(index)" class="cursor-pointer flex justify-between text-left text-slate-900">
                  <span class="text-base font-semibold leading-6">
                    {{ pergunta.pergunta }}
                  </span>
                  <span class="ml-6 flex h-7 items-center">
                    <svg v-if="!isOpen[index]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                      class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                    </svg>
                  </span>
                </dt>
                <dd v-if="isOpen[index]" class="mt-2 pr-12">
                  <p class="text-base leading-7 text-slate-600">
                    {{ pergunta.resposta }}
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  async created() {
    this.isOpen = Array(this.perguntas.length).fill(false);
    const slug = this.$route.params.slug;
    const response = await axios.get(`/api/produtos/${slug}`);
    this.produto = response.data;
    console.log('cor aqui:' + this.produto.cores);
    this.perguntas = this.produto.duvidas;
    this.tabs.forEach((tab) => {
      tab.content = this.produto[tab.field] ? this.toHtml(tab.field) : null;
    });
    this.tabs = this.tabs.filter((tab) => tab.content);
  },
  data() {
    return {
      produto: null,
      tabs: [
        { field: "indicacao", title: "Indicação" },
        { field: "preparo", title: "Preparo" },
        { field: "aplicacao", title: "Aplicação" },
        { field: "rendimento", title: "Rendimento" },
        { field: "estocagem", title: "Estocagem" },
        { field: "composicao", title: "Composição" },
        { field: "recomendacao", title: "Recomendação" },
      ],
      perguntas: [
        {
          pergunta: "",
          resposta: ""
        },
      ],
      isOpen: [],
      largura: "",
      total: "0.00",
      comprimento: "",
      profundidade: "0",
      largura_fuga: "",
      assentamento: "",
    };
  },
  methods: {
    getTabContent(field) {
      const tab = this.tabs.find(t => t.field === field);
      return tab ? tab.content : '';
    },
    toggle(index) {
      this.isOpen[index] = !this.isOpen[index];
    },
    toHtml(field) {
      const paragraphs = this.produto[field].split("\n");
      return paragraphs.map((paragraph) => `<p>${paragraph}</p>`).join("");
    },
    calcular() {
      const largura = parseFloat(this.largura);
      const comprimento = parseFloat(this.comprimento);
      const profundidade = parseFloat(this.profundidade);
      const largura_fuga = parseFloat(this.largura_fuga);
      const assentamento = parseFloat(this.assentamento);
      const total =
        (((largura * 10 + comprimento * 10) * profundidade * largura_fuga * this.valor) /
          (largura * comprimento * 100)) *
        assentamento;
      this.total = total.toFixed(2);
    },
  },
  computed: {
    valor() {
      if (!this.produto) return 0;
      if (this.produto.id == 14) return 1.58;
      if (this.produto.id == 5 || this.produto.id == 7 || this.produto.id == 18) return 1.75;
      if (this.produto.id == 12) return 2.54;
      return 0;
    },
  },
};
</script>

<style scoped>
li>button {
  color: black;
}

.aspect-ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.bg-produto-banner {
  background-image: url('../../img/produto-banner.png');
  background-size: cover;
  background-position: center;
}
</style>
