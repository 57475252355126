<template>
  <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor" style="height: 304px; margin-bottom: 2rem;">
    <div class="container max-w-[1200px] relative z-10">
      <h3 class="text-[48px] md:text-[64px] text-white text-left">Downloads</h3>
    </div>
  </div>
  <div class="mx-auto max-w-7xl sm:px-6  lg:px-8">
    <div class="relative isolate overflow-hidde px-6 py-16 text-center sm:px-16">
      <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-black sm:text-4xl">Materiais para Download
      </h2>
      <p class="mx-auto mt-6 max-w-xl text-lg leading-6 text-gray-400">Aqui você encontra todos os materiais da Inkor
        disponíveis para dowload, incluindo catálogos, manuais, técnicos e folhetos informativos, para facilitar o
        acesso às infomações que você precisa.
      </p>
    </div>
  </div>
  <div class="mx-auto px-4 lg:px-8 mb-6">
    <ul role="list" class="mx-auto mt-2 grid max-w-5xl grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4">
      <li class="flex justify-center">
        <a class="w-full max-w-[400px] overflow-hidden rounded-3xl"
          href="https://marketinginkor.com.br/catalogo-de-ferramentas/" target="_blank">
          <img class="w-full h-auto object-contain" src="../../img/catalogo-ferramentas.png"
            alt="Catálogo Ferramentas" />
        </a>
      </li>
      <li class="flex justify-center">
        <a class="w-full max-w-[400px] overflow-hidden rounded-3xl"
          href="https://marketinginkor.com.br/catalogo-de-produtos/" target="_blank">
          <img class="w-full h-auto object-contain" src="../../img/catalogo-produtos.png" alt="Catálogo Produtos" />
        </a>
      </li>
      <li class="flex justify-center">
        <a class="w-full max-w-[400px] overflow-hidden rounded-3xl"
          href="https://marketinginkor.com.br/catalogo-de-tintas/" target="_blank">
          <img class="w-full h-auto object-contain" src="../../img/catalogo-cores.png" alt="Catálogo Cores" />
        </a>
      </li>
    </ul>
  </div>
  <div class="mx-auto max-w-7xl sm:px-6  lg:px-8">
    <div class="relative isolate overflow-hidde px-6 py-16 text-center sm:px-16">
      <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-black sm:text-4xl">Materiais Institucionais
      </h2>
    </div>
  </div>
  <div class="mx-auto px-4 lg:px-8 mb-28 mt-6">
    <ul role="list" class="mx-auto mt-2 grid max-w-5xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
      <li class="flex justify-center">
        <a class="flex items-center w-full max-w-[400px] overflow-hidden" href="/documents/PDF.rar" target="_blank">
          <img class="w-20 h-auto object-contain" src="../../img/download-icon.png" alt="" />
          <div class="ml-6 text-xl">Logotipo Inkor</div>
        </a>
      </li>
      <li class="flex justify-center">
        <a class="flex items-center w-full max-w-[400px] overflow-hidden"
          href="/documents/Certificado Sistema de Gestao Ambiental.pdf" target="_blank">
          <img class="w-20 h-auto object-contain" src="../../img/download-icon.png" alt="" />
          <div class="ml-6 text-xl">Certificado <br />(Sistema de Gestão Ambiental)</div>
        </a>
      </li>
      <li class="flex justify-center">
        <a class="flex items-center w-full max-w-[400px] overflow-hidden" href="/documents/manual-da-marca_inkor.pdf"
          target="_blank">
          <img class="w-20 h-auto object-contain" src="../../img/download-icon.png" alt="" />
          <div class="ml-6 text-xl">Manual da Marca</div>
        </a>
      </li>
      <li class="flex justify-center">
        <a class="flex items-center w-full max-w-[400px] overflow-hidden"
          href="/documents/Certificado Sistemas de Gestao de Saude e Seguranca Ocupacional.pdf" target="_blank">
          <img class="w-20 h-auto object-contain" src="../../img/download-icon.png" alt="" />
          <div class="ml-6 text-xl">Certificado <br />(Sistema de Gestão de Saúde e Segurança Ocupacional) </div>
        </a>
      </li>
      <li class="flex justify-center">
        <a class="flex items-center w-full max-w-[400px] overflow-hidden" href="/documents/ESG.pdf" target="_blank">
          <img class="w-20 h-auto object-contain" src="../../img/download-icon.png" alt="" />
          <div class="ml-6 text-xl">Certificado de Regularidade</div>
        </a>
      </li>
      <li class="flex justify-center">
        <a href="/documents/Certificado Sistema de Gestao da Qualidade.pdf"
          class="flex items-center w-full max-w-[400px] overflow-hidden" target="_blank">
          <img class="w-20 h-auto object-contain" src="../../img/download-icon.png" alt="" />
          <div class="ml-6 text-xl">Certificado <br />(Sistema de Gestão de Qualidade)</div>
        </a>
      </li>
    </ul>
  </div>
</template>
