<template>
  <main>
    <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor"
      style="height: 304px; margin-bottom: 2rem;">
      <div class="container max-w-[1200px] relative z-10">
        <h3 class="text-[48px] md:text-[64px] text-white">Politica de<br>Privacidade</h3>
      </div>
    </div>
    <section class="mt-5 mb-5" id="breadcrumb">
      <div class="container max-w-[1200px]">
        <a href="/"> home </a> &gt; <a href="/politica-de-privacidade"> política de privacidade </a>
      </div>
    </section>
    <article class="container max-w-[1200px] my-5">
      <section class="mb-5">
        <p>
          Na Inkor — Indústria Catarinense de Colas e Rejuntes Ltda., privacidade e segurança são prioridades e nos
          comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários/clientes. Por isso, esta
          presente Política de Privacidade estabelece como é realizada a coleta, uso e transferência de informações de
          clientes ou outras pessoas que acessam ou usam nosso site.
        </p>
        <p>
          Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas formas
          descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições
          consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.
        </p>
        <p>
          Dessa forma, a Inkor — Indústria Catarinense de Colas e Rejuntes Ltda., doravante denominada simplesmente como
          “Inkor”, inscrita no CNPJ/MF sob o n.º 06.101.244/0001-47, no papel de Controladora de Dados, obriga-se ao
          disposto na presente Política de Privacidade.
        </p>
      </section>
      <section class="mb-5">
        <h2>
          <strong> 1. Quais dados coletamos sobre você e para qual finalidade? </strong>
        </h2>
        <p>
          Nosso site coleta e utiliza alguns dados pessoais seus, para viabilizar a prestação de serviços e aprimorar a
          experiência de uso. A Inkor — Indústria Catarinense de Colas e Rejuntes Ltda., não se utiliza de nenhum
          software, aplicativo ou mecanismo que tenha como objetivo a coleta ou obtenção de qualquer dado pessoal sem o
          conhecimento e a anuência do usuário.
        </p>
        <p>
          O site poderá, eventualmente, ter dispositivos que coletem de forma automática algumas informações não
          consideradas pessoais, tais como número de IP, tipo e versão do navegador, sistema operacional, hora e data de
          acesso.
        </p>
      </section>
      <aside class="bg-lighter rounded p-4 mb-5">
        <p class="m-0">
          <em>
            “Cookies” são pequenos blocos de texto instalados pelos websites no disco rígido do computador do usuário.
            Eventualmente, o site da Inkor — Indústria Catarinense de Colas e Rejuntes Ltda., poderá utilizar-se de
            “cookies”, entretanto, estes não estarão habilitados para coletar informações pessoais dos usuários. Se
            utilizados, o serão única e exclusivamente para geração de estatísticas de acesso visando aprimorar o
            conteúdo do site. A maioria dos navegadores admite a instalação automática dos “cookies”, mas o usuário pode
            configurar seu navegador para não os aceitar.
          </em>
        </p>
      </aside>
      <section class="mb-5">
        <h2><strong>2. Como coletamos os seus dados?</strong></h2>
        <p>
          As informações pessoais dos usuários, tais como dados de contato (nome, telefone, endereço, etc.), são por
          estes fornecidos, espontaneamente, por intermédio de campos identificados para coleta de informações
          (Formulários de Cadastramento, Fale Conosco e outros).
        </p>
      </section>
      <section class="mb-5">
        <h3>2.1. Consentimento</h3>
        <p>
          É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre,
          informada e inequívoca pela qual você autoriza a Inkor a tratar seus dados.
        </p>
        <p>
          Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e
          armazenados mediante prévio e expresso consentimento. O seu consentimento será obtido de forma específica para
          cada finalidade acima descrita, evidenciando o compromisso de transparência e boa-fé da Inkor para com seus
          usuários/clientes, seguindo as regulações legislativas pertinentes.
        </p>
        <p>
          Ao utilizar os serviços da Inkor e fornecer seus dados pessoais, você está ciente e consentindo com as
          disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los. A qualquer tempo
          e sem nenhum custo, você poderá revogar seu consentimento. É importante destacar que a revogação do
          consentimento para o tratamento dos dados pode implicar a impossibilidade do desempenho adequado de alguma
          funcionalidade do site que dependa da operação. Tais consequências serão informadas previamente.
        </p>
      </section>
      <section class="mb-5">
        <h2><strong>3. Quais são os seus direitos?</strong></h2>
        <p>
          A Inkor assegura a seus usuários/clientes seus direitos de titular previstos no
          <a class="text-dark" href="https://lgpd-brasil.info/capitulo_03/artigo_18" style="text-decoration: underline">
            Artigo 18 da Lei Geral de Proteção de Dados
          </a>
          . Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
        </p>
        <ul>
          <li>
            Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.
          </li>
          <li>
            Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio eletrônico,
            seguro e idôneo.
          </li>
          <li>Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.</li>
          <li>
            Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através
            da anonimidade, bloqueio ou eliminação.
          </li>
          <li>
            Solicitar a portabilidade de seus dados, por um relatório de dados cadastrais que a Inkor trata a seu
            respeito.
          </li>
          <li>Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.</li>
          <li>Revogar seu consentimento, desautorizando o tratamento de seus dados.</li>
          <li>
            Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa.
          </li>
        </ul>
      </section>
      <section class="mb-5">
        <h2>
          <strong>4. Como você pode exercer seus direitos de titular?</strong>
        </h2>
        <p>
          Para exercer seus direitos de titular, você deve entrar em contato com a Inkor através dos seguintes meios
          disponíveis:
        </p>
        <address class="bg-lighter rounded p-4 mb-5 d-inline-flex-column">
          <p>
            <strong>E-mail:</strong>
            <a class="text-dark"> &nbsp;lgpd@inkor.com.br </a>
          </p>
        </address>
        <p>
          Para garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é possível
          que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa hipótese, você
          será informado previamente.
        </p>
      </section>
      <section class="mb-5">
        <h2>
          <strong> 5. Como e por quanto tempo seus dados serão armazenados? </strong>
        </h2>
        <p>
          Seus dados pessoais coletados pela Inkor serão utilizados e armazenados durante o tempo necessário para a
          prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas,
          considerando os direitos dos titulares dos dados e dos controladores.
        </p>
        <p>Geralmente, seus dados serão mantidos enquanto a relação contratual entre você e a Inkor perdurar.</p>
        <p>
          Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou
          anonimizados, ressalvadas as hipóteses legalmente previstas no
          <a class="text-dark" href="https://lgpd-brasil.info/capitulo_02/artigo_16" style="text-decoration: underline">
            Artigo 16 da Lei Geral de Proteção de Dados
          </a>
          , a saber:
        </p>
        <ol type="i">
          <li>cumprimento de obrigação legal ou regulatória pelo controlador;</li>
          <li>estudo por órgão de pesquisa, garantida, sempre que possível, a anonimidade dos dados pessoais;</li>
          <li>
            transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;
          </li>
          <li>uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
        </ol>
        <p>
          Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais,
          judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e
          administrativos serão mantidas, a despeito da exclusão dos demais dados.
        </p>
        <p>
          O armazenamento de dados coletados pela Inkor reflete o nosso compromisso com a segurança e privacidade dos
          seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a confidencialidade,
          integridade e inviolabilidade dos seus dados.
        </p>
        <p>
          Além disso, também contamos com medidas de segurança apropriadas aos riscos e com controle de acesso às
          informações armazenadas.
        </p>
      </section>
      <section class="mb-5">
        <h2>
          <strong>6. O que fazemos para manter seus dados seguros?</strong>
        </h2>
        <p>
          Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais
          orientadas para a proteção da sua privacidade. Aplicamos essas ferramentas considerando os dados pessoais
          coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os
          direitos e liberdades do titular dos dados coletados e tratados.
        </p>
        <p>Entre as medidas que adotamos, destacamos as seguintes:</p>
        <ul>
          <li>Apenas pessoas autorizadas têm acesso a seus dados pessoais;</li>
          <li>O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade</li>
          <li>Seus dados pessoais são armazenados em ambiente seguro e idôneo.</li>
        </ul>
        <p>
          A Inkor se compromete a adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário
          destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos
          os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques
          cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário/cliente.
        </p>
        <p>
          Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você ou qualquer um de nossos
          usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido,
          em consonância com as disposições da Lei Geral de Proteção de Dados.
        </p>
      </section>
      <section class="mb-5">
        <h2>
          <strong>7. Com quem seus dados podem ser compartilhados?</strong>
        </h2>
        <p>
          Tendo em vista a preservação de sua privacidade, a Inkor não compartilhará seus dados pessoais com nenhum
          terceiro não autorizado. No entanto, existem outras hipóteses em que seus dados poderão ser compartilhados,
          sendo:
        </p>
        <ul>
          <li>
            Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas
            ou governamentais competentes;
          </li>
          <li>Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática;</li>
          <li>Proteção dos direitos da Inkor em qualquer categoria de conflito, inclusive os de teor judicial.</li>
        </ul>
      </section>
      <section class="mb-5">
        <h2><strong>8. Cookies ou dados de navegação</strong></h2>

        <p>
          A Inkor usa cookies, arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que
          contém informações relacionadas à navegação do site. Em suma, os cookies são utilizados para aprimorar a
          experiência de uso.
        </p>
        <p>
          Ao acessar nosso site e consentir com o uso de cookies, você manifesta conhecer e aceitar a utilização de um
          sistema de coleta de dados de navegação com o uso de cookies em seu dispositivo. Você pode, a qualquer tempo e
          sem nenhum custo, alterar as permissões, bloquear ou recusar os cookies. Todavia, a revogação do consentimento
          de determinados cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
        </p>
        <p></p>
        <p>
          Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área
          de gerenciamento de cookies. Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
        </p>
        <ul>
          <li>
            <a class="text-dark" style="text-decoration: underline"
              href="https://support.google.com/accounts/answer/61416?hl=pt-BR&co=GENIE.Platform%3DAndroid">
              Google Chrome
            </a>
          </li>
          <li>
            <a class="text-dark" style="text-decoration: underline"
              href="https://support.mozilla.org/pt-PT/kb/cookies-informacao-que-websites-guardam-no-seu-computador">
              Firefox
            </a>
          </li>
          <li>
            <a class="text-dark" style="text-decoration: underline"
              href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">
              Safari
            </a>
          </li>
          <li>
            <a class="text-dark" style="text-decoration: underline"
              href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
              Microsoft Edge
            </a>
          </li>
          <li>
            <a class="text-dark" style="text-decoration: underline"
              href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
              Internet Explorer
            </a>
          </li>
          <li>
            <a class="text-dark" style="text-decoration: underline"
              href="https://help.opera.com/en/latest/web-preferences/">
              Opera
            </a>
          </li>
        </ul>
      </section>
      <section class="mb-5">
        <h2><strong>9. Alteração desta Política de Privacidade</strong></h2>
        <p>
          A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em 24 de junho de 2021.
        </p>
        <p>
          Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da
          adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos que você a revise
          com frequência.
        </p>
        <p>
          Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos
          acerca das mudanças ocorridas. Ao utilizar nossos serviços e fornecer seus dados pessoais após tais
          modificações, você as consente.
        </p>
      </section>
      <section class="mb-5">
        <h2><strong>10. Responsabilidade</strong></h2>
        <p>
          A Inkor prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, conforme os
          artigos do
          <a class="text-dark" href="https://lgpd-brasil.info/capitulo_06/artigo_42" style="text-decoration: underline">
            Artigo 42
          </a>
          <a class="text-dark" href="https://lgpd-brasil.info/capitulo_08/artigo_52" style="text-decoration: underline">
            Artigo 52
          </a>
          da Lei Geral de Proteção de Dados. Comprometemo-nos em manter esta Política de Privacidade atualizada,
          observando suas disposições e zelando por seu cumprimento. Além disso, também assumimos o compromisso de
          buscar condições técnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.
          Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de
          dados realizado pela Inkor, comprometemo-nos a segui-las.
        </p>
        <h3>10.1 Isenção de responsabilidade</h3>
        <p>
          Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança de modo a evitar incidentes,
          não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a Inkor não se responsabiliza por:
        </p>
        <ol>
          <li>
            Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus
            dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de
            dados e do cumprimento das finalidades descritas no presente instrumento. Destacamos que a responsabilidade
            em relação à confidencialidade dos dados de acesso é do usuário;
          </li>
          <li>
            Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada
            da Inkor. Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para
            você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de
            Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias;
          </li>
          <li>
            Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos
            serviços da Inkor; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de
            inteiramente responsabilidade do usuário/cliente.
          </li>
        </ol>
      </section>
      <section class="mb-5">
        <h2><strong>11. Encarregado de Proteção de Dados</strong></h2>
        <p>
          A Inkor disponibiliza os seguintes meios para você poder entrar em contato conosco para exercer seus direitos
          de titular:
        </p>
        <address class="bg-lighter rounded p-4 mb-5 d-inline-flex-column">
          <p>
            <strong>E-mail:</strong>
            <a class="text-dark"> &nbsp;lgpd@inkor.com.br </a>
          </p>
        </address>
        <p>
          Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode
          entrar em contato com o nosso Encarregado de Proteção de Dados Pessoais, através dos seguintes canais:
        </p>
        <address class="bg-lighter rounded p-4 mb-5 d-inline-flex-column">
          <p>
            <strong>Encarregado:</strong>
            &nbsp;Hamilto Anacleto Rosa
          </p>
          <p class="m-0">
            <strong>E-mail:</strong>
            <a class="text-dark"> &nbsp;lgpd@inkor.com.br </a>
          </p>
        </address>
      </section>
    </article>
  </main>
</template>
