<template>
  <header>
    <nav class="bg-zinc-900 py-3 index-up relative">
      <div class="container max-w-[1200px] mx-auto flex flex-wrap items-center justify-between">
        <a class="text-white text-xl font-bold" href="/">
          <img src="../../img/inkor-logo.webp" alt="Inkor" class="h-10 inline-block align-middle" />
        </a>
        <button class="text-white inline-block sm:hidden" @click="isMenuOpen = !isMenuOpen">
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        <div :class="{ 'block': isMenuOpen, 'hidden': !isMenuOpen }" class="w-full sm:flex sm:items-center sm:w-auto">
          <ul class="sm:flex sm:space-x-4 text-white text-right">
            <li class="py-2 sm:py-0">
              <router-link class="block sm:inline-block text-white hover:font-semibold" :to="{ name: 'sobre' }">Sobre nós</router-link>
            </li>
            <li class="py-2 sm:py-0">
              <router-link class="block sm:inline-block text-white hover:font-semibold" :to="{ name: 'blog' }">Blog</router-link>
            </li>
            <!-- Produtos Dropdown -->
            <li class="relative py-2 sm:py-0 hover:font-semibold"
                @mouseenter="openDropdown"
                @mouseleave="closeDropdownWithDelay">
              <a class="block sm:inline-block cursor-pointer text-white flex items-center justify-end"
                 @click.prevent="navigateToAllProducts">
                Produtos
                <svg class="ml-1 w-4 h-4 inline-block" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0L5.21 8.27a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"></path>
                </svg>
              </a>

              <ul :class="{ 'block': isDropdownOpen, 'hidden': !isDropdownOpen }"
                class="bg-white absolute left-0 w-full sm:w-[300px] text-left rounded-lg z-10 mt-2 sm:mt-0 p-4 space-y-4 sm:space-y-0">
                <li class="text-zinc-900 font-semibold text-lg mb-2">
                  Linha de Produtos
                </li>

                <div class="grid grid-cols-1 gap-1.5">
                  <li v-for="(cat, index) in categorias" :key="index">
                    <a class="flex items-center text-zinc-800 hover:font-semibold hover:text-black transition-colors"
                      :href="$router.resolve({ name: 'produtos', query: { categoria: cat.nome, c: cat.id } }).href">
                      {{ cat.nome }}
                    </a>
                  </li>
                </div>
              </ul>
            </li>
            <li class="py-2 sm:py-0">
              <a class="block sm:inline-block text-white hover:font-semibold" href="/contato">Contato</a>
            </li>
            <li class="py-2 sm:py-0">
              <a class="block sm:inline-block text-white hover:font-semibold" href="/downloads">Downloads</a>
            </li>
          </ul>
          <div class="hidden sm:flex items-center ml-4">
            <div class="relative">
              <input type="text" placeholder="Digite aqui o que procura"
                class="border rounded w-full py-2 px-4 leading-tight focus:outline-none focus:shadow-outline"
                @keyup.enter.prevent="search" v-model="searchTerm" />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      categorias: [],
      searchTerm: "",
      isMenuOpen: false,
      isDropdownOpen: false,
      closeDropdownTimeout: null,
    };
  },
  created() {
    this.fetchCategoriasComSubcategorias();
  },
  methods: {
    // Função de busca
    search() {
      this.$router.push({ name: "produtos", query: { busca: this.searchTerm } });
    },
    navigateToAllProducts() {
      this.$router.push({ name: "produtos"});
    },
    // Carrega as categorias com subcategorias
    async fetchCategoriasComSubcategorias() {
      this.categorias = (await axios.get("/api/categorias-subcategorias")).data;
    },
    // Abre o dropdown ao passar o mouse
    openDropdown() {
      clearTimeout(this.closeDropdownTimeout);
      this.isDropdownOpen = true;
    },
    // Fecha o dropdown com um pequeno atraso
    closeDropdownWithDelay() {
      this.closeDropdownTimeout = setTimeout(() => {
        this.isDropdownOpen = false;
      }, 50);
    }
  },
  beforeDestroy() {
    clearTimeout(this.closeDropdownTimeout);
  }
};
</script>

<style scoped>
.boxTradutor {
  display: inline-block;
}

nav.bg-zinc-900 {
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.index-up {
  z-index: 999;
}
</style>
