import { createWebHistory, createRouter } from "vue-router";

import ApplicationLayout from "../layout/ApplicationLayout.vue";

import Artigo from "../views/Artigo.vue";
import Blog from "../views/Blog.vue";
import Contato from "../views/Contato.vue";
import ContatoEspecialista from "../views/ContatoEspecialista.vue";
import ContatoRepresentante from "../views/ContatoRepresentantes.vue";
import ContatoRepresentar from "../views/ContatoQueroRepresentar.vue";
import Home from "../views/Home.vue";
import Privacidade from "../views/Privacidade.vue";
import Produto from "../views/Produto.vue";
import Shop from "../views/Shop.vue";
import SimuladorRejuntes from "../views/SimuladorRejuntes.vue";
import SimuladorTintas from "../views/SimuladorTintas.vue";
import SobreNos from "../views/SobreNos.vue";
import Downloads from "../views/Downloads.vue";
import Calculadora from "../views/Calculadora.vue";

const routes = [
  {
    path: "/",
    component: ApplicationLayout,
    children: [
      { path: "/", name: "home", component: Home },
      { path: "blog", name: "blog", component: Blog },
      { path: "blog/artigo/:slug", name: "blog.artigo", component: Artigo },
      { path: "contato", name: "contato", component: Contato },
      { path: "contato-quero-representar", name: "contato.representar", component: ContatoRepresentar },
      { path: "contato-representante", name: "contato.representantes", component: ContatoRepresentante },
      { path: "contato/especialista", name: "contato.especialista", component: ContatoEspecialista },
      { path: "politica-de-privacidade", name: "privacidade", component: Privacidade },
      { path: "produto/:slug", name: "produtos.produto", component: Produto },
      { path: "produtos", name: "produtos", component: Shop },
      { path: "simulador-rejuntes", name: "simulador.rejuntes", component: SimuladorRejuntes },
      { path: "simulador-tintas", name: "simulador.tintas", component: SimuladorTintas },
      { path: "sobre-nos", name: "sobre", component: SobreNos },
      { path: "downloads", name: "downloads", component: Downloads },
      { path: "calculadora", name: "calculadora", component: Calculadora },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
