<template>
  <div>

    <div class="block md:hidden bg-[#EDEDED] text-center py-4 text-base">Copyright © {{ new Date().getFullYear() }} Uma
      marca
      INKOR
      Todos os direitos reservados. Indústria Catarinense de Colas e Rejuntes Ltda - CNPJ 06.101.244/0001-47</div>

    <footer class="hidden md:block">
      <div class="container py-5">
        <div class="flex flex-col items-center justify-center text-slate-50">
          <div class="flex flex-wrap justify-center w-full max-w-7xl">
            <div class="w-full sm:w-1/4 p-4">
              <h5 class="mb-3 font-bold">A Inkor</h5>
              <p>
                <a href="/sobre-nos" class="text-slate-50 hover:text-red-500">
                  Sobre nós
                </a>
              </p>
              <p>
                <router-link to="/sobre-nos#nossa-historia" class="text-slate-50 hover:text-red-500">
                  Nossa história
                </router-link>
              </p>
              <p>
                <router-link to="/sobre-nos#sustentabilidade" class="text-slate-50 hover:text-red-500">
                  Sustentabilidade
                </router-link>
              </p>
              <p>
                <a href="/contato" class="text-slate-50 hover:text-red-500">
                  Fale conosco
                </a>
              </p>
              <p>
                <a href="/blog" class="text-slate-50 hover:text-red-500">
                  Blog
                </a>
              </p>
            </div>

            <div class="w-full sm:w-1/4 p-4">
              <h5 class="mb-3 font-bold">Fale com a gente</h5>
              <p class="mb-3">48 3356-5400</p>
              <p class="mb-3">sac@inkor.com.br</p>
              <p class="mt-3">
                De segunda a sexta,
                <br />
                das 8h às 18h
              </p>
            </div>

            <div class="w-full sm:w-1/4 p-4">
              <h5 class="mb-3 font-bold">Produtos</h5>
              <p>
                <a href="/documents/Catalogo-2024.pdf" target="_blank" class="text-slate-50 hover:text-red-500">
                  Catálogo 2024
                </a>
              </p>
              <p>
                <a href="/produtos?categoria=Aditivos&c=7" class="text-slate-50 hover:text-red-500">
                  Aditivos
                </a>
              </p>
              <p>
                <a href="/produtos?categoria=Argamassas&c=1" class="text-slate-50 hover:text-red-500">
                  Argamassas
                </a>
              </p>
              <p>
                <a href="/produtos?categoria=Complementos+para+Tinta&c=9" class="text-slate-50 hover:text-red-500">
                  Complementos
                </a>
              </p>
              <p>
                <a href="/produtos?categoria=Impermeabilizante&c=5" class="text-slate-50 hover:text-red-500">
                  Impermeabilizantes
                </a>
              </p>
              <p>
                <a href="/produtos?categoria=Saneantes&c=7" class="text-slate-50 hover:text-red-500">
                  Saneantes
                </a>
              </p>
              <p>
                <a href="/produtos?categoria=Tintas+Imobiliárias&c=8" class="text-slate-50 hover:text-red-500">
                  Tintas
                </a>
              </p>
            </div>

            <div class="w-full sm:w-1/6 p-2 mt-3">
              <h4 class="font-bold mb-4 text-center">
                Siga
                <br />
                nas
                <br />
                redes
              </h4>
              <a href="https://www.instagram.com/instainkor" target="_blank" class="block mb-2 text-center">
                <img src="../../img/instagram.png" class="w-8 h-auto mx-auto" />
              </a>
              <a href="https://www.facebook.com/inkor.industria" target="_blank" class="block mb-2 text-center">
                <img src="../../img/facebook.png" class="w-8 h-auto mx-auto" />
              </a>
              <a href="https://www.youtube.com/channel/UCmRWuPGeCxJ9cWqCwvX5BXg" target="_blank"
                class="block text-center ">
                <img src="../../img/youtube.png" class="w-9 h-auto mx-auto" />
              </a>
            </div>
          </div>
        </div>

        <div class="col-sm-12 text-center direitos -white mt-5">
          <span class="me-2">© Copyright 2003 - {{ new Date().getFullYear() }}</span>
          |
          <span class="ms-2 me-2">Inkor - 06.101.244/0001-47</span>
          |
          <span class="ms-2 me-2">Todos os direitos reservados</span>
          |
          <a href="/politica-de-privacidade" class="ms-2 -white">
            Política de privacidade
          </a>
        </div>
      </div>
    </footer>
  </div>

</template>

<script>
export default {
  methods: {
    buscarPor(query) {
      this.$router.push({ name: "produtos", query });
    },
  },
};
</script>
