<template>
  <div style="position: fixed; z-index: 1000">
    <img id="svgAbrirModal" src="../../img/icon_new_lgpd.png" alt="Abrir Modal"
      style="margin-bottom: 100px; cursor: pointer; position: fixed; bottom: 250px; left: 0; z-index: 1000;"
      class="w-11 h-auto" />
  </div>

  <div id="meuModal" class="modal"
    style="display: none; position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%); z-index: 1010">
    <!-- Conteúdo do modal -->
    <div class="modal-conteudo" style="position: relative">
      <span class="fechar" style="position: absolute; right: 10px; top: 10px; cursor: pointer">&times;</span>
      <img id="imagem-icone" src="../../img/icon_lgpd2.webp" alt=""
        style="display: block; margin-left: auto; margin-right: auto; margin-top: 25px" />
      <p id="texto-lei">
        A Inkor tem o compromisso de proteger e respeitar a privacidade dos dados pessoais.
        <br /><br />
        Para efeitos da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais), alterada pela Lei nº 13.853/2019,
        a Inkor, por meio do seu Controlador de Dados – Luiz Gonzaga Carvalho – indica o Sr. Hamilto Anacleto Rosa, para
        atender ao artigo 41 da Lei 13709/2018 quanto à atribuição de Encarregado de Dados / DPO – Data Protection
        Officer para o tratamento de dados pessoais oriundos da atividade da organização, competindo-lhe:
        <br /><br />
        Artigo 41 - § 2º As atividades do Encarregado de dados consistem em:
        <br /><br />
        I - Aceitar reclamações e comunicações dos titulares, prestar esclarecimentos e adotar providências; II -
        Receber comunicações da autoridade nacional e adotar providências; III - Orientar os funcionários e os
        contratados da entidade a respeito das práticas a serem tomadas em relação à proteção de dados pessoais; e IV -
        Executar as demais atribuições determinadas pelo controlador ou estabelecidas em normas complementares.
        <br /><br />
        Para manter contato conosco, referente a Proteção de Dados Pessoais, utilize: lgpd@inkor.com.br
      </p>
    </div>
  </div>

  <BannerHome />
  <CarrosselProdutos />
  <DescubraSeuProduto />
  <div class="mt-24">.</div>
  <SimuladorAmbiente />
  <!-- <BannerSaibaMais /> -->
  <div class="hidden md:block">
    <MapaFiliais />
  </div>
  <NossosServicos />
  <!-- <InkorNews /> -->
  <AvisoFooter />
</template>

<script>
import SimuladorAmbiente from '../components/simulador-ambiente.vue';
import CarrosselProdutos from '../components/carrossel-produtos.vue';
import BannerHome from '../components/banner-home.vue';
import DescubraSeuProduto from '../components/descubra-seu-produto.vue';
import BannerSaibaMais from '../components/banner-saiba-mais.vue';
import MapaFiliais from '../components/mapa-inkor.vue';
import NossosServicos from '../components/nossos-servicos.vue';
import InkorNews from '../components/inkor-news.vue';
import AvisoFooter from '../components/aviso-footer.vue';

export default {
  components: {
    SimuladorAmbiente,
    CarrosselProdutos,
    BannerHome,
    DescubraSeuProduto,
    BannerSaibaMais,
    MapaFiliais,
    NossosServicos,
    InkorNews,
    AvisoFooter,
  },
  data() {
    return {
      show: false,
      map: {
        openedMarkerID: null,
        center: { lat: -26.23479, lng: -49.66473 },
        markers: [
          {
            id: 1,
            title: 'Imbituba - SC',
            position: { lat: -28.204906, lng: -48.699602 },
            image: '/factories/imbituba-factory.jpg',
          },
          {
            id: 2,
            title: 'Guaíra - PR',
            position: { lat: -24.089717251605343, lng: -54.26452258719748 },
            image: '/factories/guaira-factory.jpg',
          },
          {
            id: 3,
            title: 'Piên - PR',
            position: { lat: -26.0983, lng: -49.430419 },
            image: '/factories/pien-factory.jpg',
          },
          {
            id: 4,
            title: 'Rio Claro - SP',
            position: { lat: -22.349151418390974, lng: -47.5489145306369 },
            image: '/factories/rio-claro-factory.jpg',
          },
          {
            id: 5,
            title: 'Osório - RS',
            position: { lat: -29.806982241712678, lng: -50.2394996345688 },
            image: '/factories/osorio-factory.jpg',
          },
        ],
      },
    };
  },
  mounted() {
    const modal = document.getElementById('meuModal');
    const svgIcon = document.getElementById('svgAbrirModal');
    const span = document.getElementsByClassName('fechar')[0];

    svgIcon.onclick = function () {
      modal.style.display = 'block';
    };

    span.onclick = function () {
      modal.style.display = 'none';
    };

    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
  },
  methods: {
    clickMarker(id) {
      this.map.openedMarkerID = id;
    },
    buscarPor(query) {
      this.$router.push({ name: 'produtos', query });
    },
  },
};
</script>

<style>
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-conteudo {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

@media (max-width: 768px) {
  .modal-conteudo {
    padding: 25px;
    margin: 10% auto;
    width: 90%;
  }
}

#texto-lei {
  padding: 25px 100px;
  text-align: left;
}

@media (max-width: 768px) {
  #texto-lei {
    padding: 0px 0px;
  }
}

.fechar {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.fechar:hover,
.fechar:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#imagem-icone {
  height: 100px;
  display: block;
  margin: auto;
}

@media (max-width: 768px) {
  #imagem-icone {
    height: 55px;
    margin-bottom: 50px;
  }
}

#svgAbrirModal {
  margin-bottom: 150px;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 0;
  height: 400px;
  z-index: 1000;
}

@media (max-width: 768px) {
  #svgAbrirModal {
    height: 225px;
    margin-bottom: 100px;
  }
}
</style>
