<template>
  <div class="container max-w-[1200px] p-0">
    <div class="py-10">
      <div class="flex flex-col items-center md:items-start text-center md:text-left">
        <div class="flex flex-col justify-center items-center md:items-start w-[285px] md:w-full">
          <h1 class="font-semibold text-[36px] custom-line-height text-center md:text-left">
            <span class="block sm:hidden">Lançamentos</span>
            <span class="hidden md:block">Lançamentos</span>
          </h1>
          <span class="sm:text-center md:text-left w-[285px] md:w-full text-[20px] py-2">
            Confira nossos últimos lançamentos, perfeito para todos os tipos de obras.
          </span>
        </div>
        <div class="flex flex-nowrap md:flex-wrap justify-center w-full overflow-x-auto space-x-4 scrollbar-hide">
          <div v-for="(produto, index) in lancamentos" :key="index"
            class="flex flex-col items-center pt-10 cursor-pointer w-[250px] transform transition duration-300 hover:scale-105">
            <RouterLink :to="{ name: 'produtos.produto', params: { slug: produto.slug } }">
              <div class="flex flex-col items-center">
                <img :src="produto.imagem ? produto.imagem : '../../img/produto1.png'" class="object-cover h-40 w-40"
                  alt="" />
                <div class="flex flex-col items-center mt-4">
                  <span class="font-semibold text-[22px] w-[192px] text-center">{{ produto.nome }}</span>
                  <span class="text-center">{{ produto.categoria }}</span>
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "CarrosselProdutos",
  data() {
    return {
      lancamentos: []
    };
  },
  created() {
    this.buscarLancamentos();
  },
  methods: {
    async buscarLancamentos() {
      try {
        const response = await axios.get('/api/home/lancamentos');
        this.lancamentos = response.data;
        console.log(this.lancamentos);
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style scoped>
img {
  min-height: 160px;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  /* Internet Explorer e Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Safari e Chrome */
}
</style>
