<template>
  <application-nav />
  <router-view />

  <application-footer />
</template>

<script>
import ApplicationNav from "./ApplicationNav.vue";
import ApplicationFooter from "./ApplicationFooter.vue";

export default {
  components: {
    ApplicationNav,
    ApplicationFooter,
  },
};
</script>
