<template>
  <div class="relative text-[#4A4A4A]">
    <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor hidden md:block lg:block"
      style="height: 304px; z-index: 0;">
      <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor" style="height: 304px;">
        <div class="container max-w-[1200px] relative z-10">
          <h3 class="text-[48px] md:text-[64px] text-white text-left">Nosso Blog</h3>
        </div>
      </div>
    </div>
    
    <!-- Principal Container para o Blog e Filtro -->
    <div class="container max-w-[1200px] flex flex-col md:flex-row justify-between py-12 -my-[4.8rem] relative z-20 mb-2 -mt-24 bg-white">
      <!-- Seção de Blogs -->
      <div class="w-full md:w-[70%] mt-16 md:mt-0 h-auto px-8">
        <div v-if="blog.data && blog.data.length === 0" class="text-center py-8">
          <h2 class="text-[24px] font-bold">Ops! Não encontramos o que você procura.</h2>
          <p class="text-[16px] text-gray-600">Dê uma olhada em outros tópicos ou ajuste sua pesquisa.</p>
        </div>

        <div v-else>
          <div v-for="value in blog.data" :key="value.id" class="flex flex-col items-start w-full pb-8">
            <router-link :to="{ name: 'blog.artigo', params: { slug: value.slug } }" class="block w-full">
                <img v-if="value.image" :src="value.image" class="w-full h-auto mb-4 img-fluid-blog rounded-[15px]" />
                <img v-else src="../../img/tinta.jpg" class="w-full h-auto mb-4 img-fluid-blog rounded-[15px]" />
            </router-link>

            <router-link :to="{ name: 'blog.artigo', params: { slug: value.slug } }" class="text-[32px] font-bold text-[#343434]">
              {{ value.titulo }}
            </router-link>

            <span class="text-[16px] mb-4 text-[#4A4A4A]">Publicado em {{ formatDate(value.created_at) }}</span>

            <p class="text-[16px] mb-4 text-[#383838]">
              {{ value.descricao }}
            </p>

            <div class="flex text-[20px] font-semibold">
              <div class="font-bold mt-2 text-[16px]">TAGS: </div>
              <div class="flex items-center ml-2 flex-wrap">
                <span v-for="(tagObj, tagIndex) in value.tags" :key="tagIndex" class="flex items-center">
                  <span class="cursor-pointer text-[16px] mr-0.5 mt-2" @click="searchByTag(tagObj.tag.id)">
                    {{ tagObj.tag.nome }}
                  </span>
                  <span v-if="tagIndex < value.tags.length - 1" class="mr-0.5" style="align-self: flex-end;">,</span>
                </span>
              </div>
            </div>

            <hr class="md:hidden lg:hidden block w-full mt-4" />
          </div>
        </div>
      </div>

      <!-- Container lateral de 30% para "Últimos posts" e "Tags", exibido abaixo dos posts em telas pequenas -->
      <div class="w-full md:w-[30%] mt-8 md:mt-0 h-auto px-8 py-4">
        <!-- <h2 class="text-[24px] font-bold mb-4">TAGS:</h2> -->
        <div class="flex flex-wrap">
          <span v-for="tag in uniqueTags" :key="tag.id"
            class="cursor-pointer text-[20px] mr-2 mb-2 bg-gray-200 px-1.5 py-1" @click="searchByTag(tag.id)">
            {{ tag.nome }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="container pb-12">
    <Pagination :paginator="blog" @changePage="loadBlog" :options="[6, 12, 18]" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      blog: { current_page: 1, last_page: 1, per_page: 6 },
      selectedTagId: null,
      allTags: [],
      uniqueTags: [],
    };
  },
  created() {
    this.loadBlog(1);
    this.loadAllTags();
  },
  methods: {
    async loadBlog(page) {
      const tagId = this.$route.query.tag || null;
      const payload = {
        params: {
          page,
          per_page: this.blog.per_page,
          tagId,
        },
      };
      try {
        const response = await axios.get("/api/blog", payload);
        this.blog = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async loadAllTags() {
      try {
        const response = await axios.get("/api/tags/all");
        const tags = response.data;

        const uniqueTagsSet = new Set();
        tags.forEach((tag) => {
          uniqueTagsSet.add(JSON.stringify({ id: tag.id, nome: tag.nome }));
        });

        this.uniqueTags = Array.from(uniqueTagsSet).map((tag) => JSON.parse(tag));
      } catch (error) {
        console.log(error);
      }
    },

    formatDate(date) {
      const options = { day: '2-digit', month: 'long', year: 'numeric' };
      return new Date(date).toLocaleDateString("pt-BR", options);
    },

    searchByTag(tagId) {
      this.$router.push({ query: { tag: tagId, page: 1 } });
    },
  },

  watch: {
    '$route.query.tag': {
      handler() {
        this.loadBlog(this.blog.current_page);
      },
      immediate: true,
    }
  },
};
</script>

<style>
.bg-inkor {
  background-image: url('../../img/nosso-blog-banner.png');
  background-size: cover;
  background-position: center;
  position: relative;
}

.img-fluid-blog {
  width: 500px;
  height: 250px;
  object-fit: contain;
}
</style>
