<template>
  <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor" style="height: 304px; margin-bottom: 2rem;">
    <div class="flex items-center h-full container max-w-[1200px] relative z-10">
      <h1 class="text-[48px] md:text-[64px] text-white text-left mt-4">Encontre o<br>
        produto ideal<br>para sua obra</h1>
    </div>
  </div>

  <div class="container max-w-[1200px] my-5">
    <div class="row mt-5">
      <div class="px-3">
        <div v-if="isMobile" class="md:hidden block border border-dark py-3 cursor-pointer mb-10 px-3"
          @click="toggleFilters">
          {{ showFilters ? 'Esconder Filtro' : 'Filtrar por Categorias' }}
        </div>
      </div>

      <div class="col-sm-3" v-if="!isMobile || showFilters">
        <div class="col-12 category-container" v-for="(cat, indexCategoria) in categorias" :key="indexCategoria"
          :class="{ 'py-1': !cat.showSubcategorias }">
          <h5 class="font-bold text-[#343434]/75 cursor-pointer flex items-center gap-2.5 text-[16px]"
            @click="toggleSubcategorias(indexCategoria)">
            {{ cat.nome }}
            <span class="toggle-icon">
              {{ cat.showSubcategorias ? '-' : '+' }}
            </span>
          </h5>
          <hr class="my-2" />
          <ul class="" v-show="cat.showSubcategorias">
            <a :href="$router.resolve({
              name: 'produtos',
              query: {
                categoria: cat.nome,
                subcategoria: sub.nome,
                c: cat.id,
                s: sub.id,
              },
            }).href" v-for="(sub, indexSubcategoria) in cat.subcategorias" :key="indexSubcategoria">
              <li class="text-[#343434]/40 hover:text-[#343434]/80 text-[16px] font-semibold">{{ sub.nome }}</li>
            </a>
          </ul>
        </div>
        <div class="flex items-center w-full bg-[#F5333F] rounded-[10px] h-[80px] cursor-pointer"
          @click="$router.push('/downloads')">
          <div class="flex items-center gap-3 ml-4">
            <img src="../../img/download-icon.svg" alt="" style="filter: invert(1); width: 50px; height: 50px" />
            <span class="font-bold text-white text-[20px] spacing-low">Acesse os<br />downloads</span>
          </div>
        </div>
      </div>

      <div class="col-sm-9" v-if="produtos !== null">
        <div class="col-sm-12 text-center" v-if="produtos.total === 0">
          <h2 class="-gray bold text-capitalize">OPS!</h2>
        </div>
        <div class="col-sm-12 text-center" v-if="produtos.total === 0">
          <h4 class="-gray">Não encontramos os produtos da categoria selecionada.</h4>
        </div>

        <div class="col-sm-12 text-center" v-if="produtos.total !== 0">
          <h2 class="flex text-4xl justify-start w-full pb-12 -gray font-semibold">
            {{ categoriaNome }} &nbsp;
          </h2>
        </div>
        <div class="row" v-if="produtos.total !== 0">
          <div class="col-6 col-md-4 col-lg-3 text-center mb-4" v-for="value in produtos.data" :key="value.slug">
            <router-link :to="{ name: 'produtos.produto', params: { slug: value.slug } }">
              <div class="image-container">
                <img :src="value.imagem" class="fixed-size-image mb-2" />
              </div>
              <h4 class="font-bold">{{ value.nome }}</h4>
              <p class="font-regular text-muted">{{ value.sufixo }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoriaId: this.$route.query.c,
      categoriaNome: this.$route.query.categoria,
      lancamento: this.$route.query.lancamento,
      categorias: [],
      produtos: null,
      q: this.$route.query.busca,
      subcategoriaId: this.$route.query.s,
      subcategoriaNome: this.$route.query.subcategoria,
      isMobile: window.innerWidth < 768, // Define como mobile se a largura for menor que 768px
      showFilters: false,
    };
  },
  created() {
    this.fetchCategoriasComSubcategorias();
    this.search();
    window.addEventListener('resize', this.checkWindowSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods: {
    async search() {
      const params = {
        q: this.q,
        subcategoria: this.subcategoriaId,
        categoria: this.categoriaId,
        lancamento: this.lancamento,
      };
      try {
        this.produtos = await axios.get("/api/produtos", { params });
      } catch (error) {
        this.produtos = null;
      }
    },
    async fetchCategoriasComSubcategorias() {
      this.categorias = (await axios.get("/api/categorias-subcategorias")).data;
      // Adiciona `showSubcategorias` a cada categoria
      this.categorias.forEach(cat => {
        this.$set(cat, 'showSubcategorias', false);
      });
    },
    checkWindowSize() {
      this.isMobile = window.innerWidth < 768;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    toggleSubcategorias(indexCategoria) {
      this.categorias[indexCategoria].showSubcategorias = !this.categorias[indexCategoria].showSubcategorias;
    },
  },
};
</script>

<style scoped>
.image-container {
  width: 150px;
  /* Defina a largura desejada */
  height: 150px;
  /* Defina a altura desejada */
  overflow: hidden;
  /* Oculte qualquer parte da imagem que exceda os limites do contêiner */
  display: inline-block;
  /* Garanta que o contêiner se comporte como um bloco inline */
}

.fixed-size-image {
  width: 100%;
  /* Ajuste a imagem para caber na largura do contêiner */
  height: 100%;
  /* Ajuste a imagem para caber na altura do contêiner */
  object-fit: cover;
  /* Faça com que a imagem se ajuste ao contêiner sem distorção */
}

.category-container {
  margin-bottom: 1rem;
  /* Espaçamento entre as categorias */
}

.toggle-icon {
  font-size: 20px;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>