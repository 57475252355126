<template>
  <div class="col-sm-12 px-3 py-2 d-none d-sm-block"></div>
  <div class="d-flex flex-row">
    <div class="flex justify-center items-center text-slate-50 w-full bg-[#1A3554] ps-4 py-3">
      <h4 class="text-5xl">
        Siga nas redes
      </h4>
    </div>
    <a class="col-2 py-16 bg-[#011E41] d-flex align-items-center justify-content-center" target="_blank"
      href="https://www.instagram.com/instainkor">
      <img src="../../img/instagram@4x.png" class="mx-auto w-16 h-auto" />
    </a>
    <a class="col-2 py-12 bg-[#011B3B] d-flex align-items-center justify-content-center" target="_blank"
      href="https://www.facebook.com/inkor.industria/">
      <img src="../../img/facebook@4x.png" class="mx-auto w-16 h-auto" />
    </a>
    <a class="col-2 py-12 bg-[#011834] d-flex align-items-center justify-content-center text-center" target="_blank"
      href="https://www.youtube.com/channel/UCmRWuPGeCxJ9cWqCwvX5BXg">
      <img src="../../img/youtube@4x.png" class="mx-auto w-16 h-auto" />
    </a>
  </div>
</template>
