<template>
<router-link :to="{ name: 'simulador.tintas' }">
  <section class="sm:block md:hidden container">
    <div class="flex flex-col items-center">
      <div class="rounded-[15px] grid grid-cols-2" style="background-color: #F5333F; height: 149px; width: 348px">
        <div class="rounded-l-[15px] overflow-hidden" style="background-color: #343434">
          <img class="w-full h-full object-cover rounded-l-[15px]" src="../../img/simulador-ambientes.jpg" alt="">
        </div>
        <div
          class="flex items-center justify-center text-[24px] font-semibold text-white w-full h-full custom-line-height">
          <span>Simulador <br> de ambientes</span>
        </div>
      </div>
      <div class="rounded-[15px] mt-2 grid grid-cols-2"
        style="background-color: #F5333F; height: 149px; width: 348px">
        <div class="rounded-l-[15px] overflow-hidden" style="background-color: #343434">
          <img class="w-full h-full object-cover rounded-l-[15px]" src="../../img/simulador-cores.jpg" alt="">
        </div>
        <div
          class="flex items-center justify-center text-[24px] font-semibold text-white w-full h-full custom-line-height">
          <span>Simulador <br> de rejuntes</span>
        </div>
      </div>
    </div>
  </section>
</router-link>




  <div>


    <div class="container max-w-[1200px] pt-12 hidden md:block">


      <div class="flex flex-col" style="color: #343434">
        <span class="text-[36px] font-semibold">Simulador de Ambientes</span>
        <span>
          Faça uma simulação com as cores que deseja dar acabamento em seus
          ambientes.
        </span>
      </div>


      <div class="flex flex-col relative mt-10 gap-4">
        <!-- Contêiner da imagem -->
        <div class="w-full bg-gray-200 overflow-hidden">
          <img src="../../img/banner-simulador-1.jpg" alt="banner simulador"
            class="w-full h-full object-cover rounded-lg">
        </div>

        <!-- Botão sobre a imagem, com altura menor, alinhado à direita e centralizado verticalmente -->
        <a href="/simulador-tintas">
          <div
            class="absolute top-1/2 right-0 w-[30%] h-[80px] bg-[#F5333F] hover:bg-[#D13841] rounded-lg flex items-center justify-center transform -translate-y-[170px] -translate-x-10">
            <button class="text-white font-bold text-lg">
              Acessar o Simulador
            </button>
          </div>
        </a>


        <div class="w-full bg-gray-200 overflow-hidden">
          <img src="../../img/banner-simulador-2.png" alt="banner simulador"
            class="w-full h-full object-cover rounded-lg">
        </div>

        <!-- Botão sobre a imagem, com altura menor, alinhado à direita e centralizado verticalmente -->
        <a href="/simulador-rejuntes">

          <div
            class="absolute top-1/2 right-0 w-[30%] h-[80px] bg-[#F5333F] hover:bg-[#D13841] rounded-lg flex items-center justify-center transform translate-y-[75px] -translate-x-10 ">
            <button class="text-white font-bold text-lg">
              Acessar o Simulador
            </button>
          </div>
        </a>


      </div>











    </div>

  </div>



  <!-- <div class="md:block hidden container max-w-[1200px]">
    <div class="hidden md:block">
      <div class="flex flex-col">
        <div class="flex justify-center">
          <div class="flex flex-col">
            <div class="flex py-12 gap-6">
              <div class="flex flex-col" style="color: #343434">
                <span class="text-[36px] font-semibold">Simulador de Ambientes</span>
                <span>Faça uma simulação com as cores que deseja dar acabamento em seus
                  ambientes.</span>
              </div>
            </div>



            <div class="flex flex-col">
              <a href="/simulador-tintas">


                <div class="w-full bg-red-500">.</div>

                <div class="flex flex-col">
                  <img src="../../img/banner-simulador-1.jpg" alt="">
                  <div
                    class="flex items-center justify-center h-14 rounded-b-[15px] font-semibold text-lg text-white cursor-pointer bg-[#F5333F]">
                    <span>Acessar o simulador</span>
                  </div>
                </div>
              </a>
              <a href="/simulador-rejuntes">
                <div class="flex flex-col">
                  <img src="../../img/banner-simulador-2.png" alt="">
                  <div
                    class="flex items-center justify-center h-14 rounded-b-[15px] font-semibold text-lg text-white cursor-pointer bg-[#F5333F]">
                    <span>Acessar o simulador</span>
                  </div>
                </div>
              </a>
            </div>



          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  components: {
    name: 'SimuladorAmbiente'
  },
}
</script>
