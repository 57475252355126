<template>
    <div id="form">
        <div class="flex flex-row w-full -mt-12">
            <div class="col-sm-12">
                <div role="main" id="formulario-entre-em-contato-site-inkor-c8de4f568b09bccbdb1f"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';

const loadExternalScript = (src, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.onload = callback;
    document.head.appendChild(script);
};

onMounted(() => {
    loadExternalScript(
        "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js",
        createRDForm
    );
});

const createRDForm = () => {
    new RDStationForms("formulario-entre-em-contato-site-inkor-c8de4f568b09bccbdb1f", null).createForm();
};
</script>

<style>
section#form-formulario-entre-em-contato-site-inkor-c8de4f568b09bccbdb1f {}
</style>