<template>
    <div class="relative h-auto">
        <div class="relative text-white text-center pt-10 pb-14 h-full">
            <img src="../../img/descubra-seu-produto-banner.png" alt="Background Image"
                class="absolute inset-0 w-full h-full object-cover z-0">
            <div class="relative flex flex-col items-center z-10">
                <span class="text-[36px] font-semibold mb-2 w-[252px] md:w-full"
                   >
                    Descubra seu produto ideal
                </span>
                <span class="text-[20px] w-[90%] md:w-[705px]" style="font-weight: 100;">
                    Seja para pintar uma parede, assentar pisos ou aditivar sua massa, oferecemos os melhores produtos
                    para você utilizar do início ao fim da sua obra, transformando suas ideias em realidade.
                </span>

                <div style="height: 80px"></div>
            </div>
        </div>

        <div ref="productCarousel"
            class="flex justify-center absolute inset-x-0 bottom-0 translate-y-32 overflow-auto z-20">
            <div class="flex flex-nowrap">
                <a href="#" @click="buscaPor({ categoria: 'Argamassas', c: 5 })">
                    <div
                        class="flex flex-col justify-center items-center h-[215px] w-[215px] bg-white shadow-sm hover:shadow-lg m-2 rounded-2xl cursor-pointer transition duration-300 transform hover:scale-105">
                        <img class="mb-8" src="../../img/argamassa-icon.svg" alt="">
                        <span class="font-semibold">Argamassas</span>
                    </div>
                </a>
                <a href="#" @click="buscaPor({ categoria: 'Tintas', c: 8 })">
                    <div
                        class="flex flex-col justify-center items-center h-[215px] w-[215px] bg-white shadow-sm hover:shadow-lg m-2 rounded-2xl cursor-pointer transition duration-300 transform hover:scale-105">
                        <img class="mb-8" src="../../img/pinturas-imobiliarias-icon.svg" alt="">
                        <span class="font-semibold">Pinturas imobiliárias</span>
                    </div>
                </a>
                <a href="#" @click="buscaPor({ categoria: 'Impermeabilizante', c: 5 })">
                <div
                    class="flex flex-col justify-center items-center h-[215px] w-[215px] bg-white shadow-sm hover:shadow-lg m-2 rounded-2xl cursor-pointer transition duration-300 transform hover:scale-105">
                    <img class="mb-8" src="../../img/proteja-sua-casa-icon.svg" alt="">
                    <span class="font-semibold">Proteja sua casa</span>
                </div>
                </a>
                <a href="#" @click="buscaPor({ categoria: 'Aditivos', c: 1 })">
                <div
                    class="flex flex-col justify-center items-center h-[215px] w-[215px] bg-white shadow-sm hover:shadow-lg m-2 rounded-2xl cursor-pointer transition duration-300 transform hover:scale-105">
                    <img class="mb-8" src="../../img/aditivos-icon.svg" alt="">
                    <span class="font-semibold">Aditivos para suas obras</span>
                </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DescubraSeuProduto",
    mounted() {
        // Verifique se a largura da janela é menor que 768px (tailwind md breakpoint)
        if (window.innerWidth < 768) {
            const carouselElement = this.$refs.productCarousel;
            const firstItem = carouselElement.querySelector("div:first-child");
            const clientWidth = carouselElement.clientWidth;

            // Calcula a posição centralizada do primeiro item
            const firstItemWidth = firstItem.clientWidth + 16; // Considera a margem (m-2) em Tailwind CSS
            const offset = (clientWidth - firstItemWidth) / 2;

            // Centraliza o primeiro item
            carouselElement.scrollLeft = offset;
        }
    },
    methods: {
        buscaPor(query) {
            this.$router.push({ name: "produtos", query });
        },
        animateItem() {
            const element = event.currentTarget;
            element.classList.add('animate-pulse');
            setTimeout(() => {
                element.classList.remove('animate-pulse');
            }, 300); // A duração da animação deve ser igual ao tempo definido na classe `duration-300`;
        }
    },
};
</script>

<style>
.relative img.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.relative .relative.z-10 {
    position: relative;
    z-index: 10;
}
</style>
