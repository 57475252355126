<template>
  <section>
    <div class="background-section rounded-b-[30px] py-10 md:py-0">
      <div class="flex justify-center items-center rounded-b-[30px] lg:py-16">
        <div class="container max-w-[1200px] flex flex-col lg:flex-row items-center justify-center w-full px-8 py-5 text-white">
          <img class="block lg:hidden" src="../../img/inkor-logo.webp" style="height: 50px" alt="Inkor Logo" />
          <div class="flex flex-col justify-center text-md mt-4 lg:mt-0 lg:items-start">
            <img class="hidden lg:block" src="../../img/inkor-logo.webp" style="height: 43px; width: 200px;"
              alt="Inkor Logo" />

            <span class="custom-line-height text-[40px] mt-4 lg:mt-10" style="font-weight: 300;">
              Bem-vindos à <br>um mundo de <br>oportunidades
            </span>
            <div class="flex pt-4 justify-center lg:justify-start">
              <a href="/sobre-nos">
                <div
                  class="h-12 text-center flex justify-center items-center bg-white px-4 lg:px-9 rounded-full w-full lg:w-auto h-[54px] lg:h-[64px]"
                  style="color: #f53441; width: 251px;">
                  <span>Saiba mais sobre a Inkor</span>
                </div>
              </a>
            </div>
          </div>

          <div class="flex flex-col items-center lg:items-end w-full mt-8 lg:mt-0">
            <div
              class="my-4 lg:my-0 grid grid-cols-2 lg:grid-cols-3 text-right gap-x-4 gap-y-10 lg:gap-x-8 lg:gap-y-24 custom-line-height">
              <div class="flex flex-col items-center lg:items-end col">
                <img src="../../img/75k.svg" class="h-12 sm:h-12 lg:h-20" alt="75k">
                <span class="mt-2 text-center lg:text-right">Produtos no <br> portfólio</span>
              </div>
              <div class="flex flex-col items-center lg:items-end col">
                <img src="../../img/7.svg" class="h-12 sm:h-12 lg:h-20" alt="7">
                <span class="mt-2 text-center lg:text-right">Linhas de <br> produtos</span>
              </div>
              <div class="flex flex-col items-center lg:items-end col">
                <img src="../../img/18.svg" class="h-12 sm:h-12 lg:h-20" alt="18">
                <span class="mt-2 text-center lg:text-right">Grandes <br> Marcas</span>
              </div>
              <div class="flex flex-col items-center lg:items-end col">
                <img src="../../img/20.svg" class="h-12 sm:h-12 lg:h-20" alt="20">
                <span class="mt-2 text-center lg:text-right">Anos de<br> mercado</span>
              </div>
              <div class="flex flex-col items-center lg:items-end col">
                <img src="../../img/500.svg" class="h-12 sm:h-12 lg:h-20" alt="500">
                <span class="mt-2 text-center lg:text-right">Colaboradores <br> contratados</span>
              </div>
              <div class="flex flex-col items-center lg:items-end col">
                <img src="../../img/5.svg" class="h-12 sm:h-12 lg:h-20" alt="5">
                <span class="mt-2 text-center lg:text-right">Parques fabris <br>pelo Brasil</span>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'BannerSaibaMais',
}
</script>

<style>
.background-section {
  position: relative;
  background-image: url('../../img/banner-historia-inkor.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.background-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 35, 77, 0.8);
  /* Ensure overlay covers entire section */
  z-index: 0;
  border-radius: inherit;
  /* To match the border-radius of the section */
}
</style>
