<template>
  <section class="mt-5 mb-5" id="breadcrumb">
    <div class="container">
      <a href="/">home</a>
      &gt;
      <a href="/simulador-rejuntes">simulador de rejuntes</a>
    </div>
  </section>
  <section id="sobre-page" class="mt-5">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3">
          <h3 class="bold">Simulador de rejuntes</h3>
        </div>
      </div>
      <div class="row">
        <!-- Mostra a simulação -->
        <div class="col-sm-8 mb-5 tab-content" id="pills-tabContent">
          <div v-for="(tab, index) in imagens" :key="index" class="tab-pane fade"
            :class="{ show: index === 0, active: index === 0 }" :id="tab.title" role="tabpanel" tabindex="0"
            :style="myStyle">
            <img :src="tab.imagem" class="img-fluid" />
          </div>
        </div>
        <!-- Lista de cores -->
        <div class="col-sm-4 mb-5">
          <div class="row pe-3 py-3 animate__animated animate__fadeInUp">
            <div v-for="cor in paletaCores" :key="cor.nome" class="col-3 pe-0 mb-2">
              <a href="#void" @click="changeColor(cor)" data-bs-toggle="tooltip" data-bs-placement="bottom"
                :title="cor.nome">
                <div class="square-color" :style="{ backgroundColor: cor.hexadecimal }"></div>
              </a>
              <small class="text-center">{{ cor.nome }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <nav class="nav nav-pills flex-column flex-sm-row" id="list-bt-simulador" role="tablist">
            <a v-for="(tab, index) in imagens" :key="index" class="flex-sm-fill text-sm-center bg-[#1A3554]"
              :class="{ active: index === 0 }" :href="'#' + tab.title" data-bs-toggle="pill"
              :data-bs-target="'#' + tab.title" role="tab">
              {{ tab.title }}
            </a>
          </nav>
        </div>
      </div>

      <Socials />

      <LastPosts class="mt-5" :limit="2" />
    </div>
  </section>
</template>

<script>
import RejunteCozinha from "../../img/rejunte-cozinha.png";

export default {
  data() {
    return {
      imagens: [{ title: "Cozinha", imagem: RejunteCozinha }],
      myStyle: { backgroundColor: "#a82a3b" },
      paletaCores: [],
    };
  },
  async created() {
    try {
      const payload = { params: { flag_rejuntes: 1 } };
      this.paletaCores = (await axios.get("/api/simulador-ambiente-cores", payload)).data;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    changeColor(color) {
      this.myStyle.backgroundColor = color.hexadecimal;
    },
  },
};
</script>
