<template>
  <div>
    <div class="flex items-center md:rounded-none rounded-b-[30px] bg-inkor" style="height: 304px;">
      <div class="container max-w-[1200px] relative z-10">
        <h3 class="text-[48px] md:text-[64px] text-white text-left">Fale com<br>a Inkor</h3>
      </div>
    </div>

    <div class="container max-w-[1200px] pt-20 -mt-[4.8rem]">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        
        <!-- Coluna de Formulários -->
        <div class="w-full md:col-span-1">
          <!-- Fale Conosco Formulário -->
          <div v-if="activeForm === 'faleConosco'">
            <h1 class="text-12 my-12" style="font-size: 32px;">Envie uma mensagem <br> <span style="color: #F5333F;">para a gente</span></h1>
            <span>Estamos aqui para ouvir você! <br>Deixe-nos saber suas dúvidas ou sugestões.<br>Estamos prontos para ajudar.</span>
            <div id="form">
              <div class="flex flex-col py-4">
                <span class="text-gray-500 font-bold pb-3">Qual seu nome</span>
                <input v-model="pessoaFaleConosco.nome" type="text" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
              </div>
              <div class="flex flex-col pb-4">
                <span class="text-gray-500 font-bold pb-3">Informe seu e-mail</span>
                <input v-model="pessoaFaleConosco.email" type="email" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
              </div>
              <div class="flex md:flex-row flex-col md:gap-5">
                <div class="flex flex-col pb-4 w-full">
                  <span class="text-gray-500 font-bold pb-3">DDD + Telefone</span>
                  <input v-model="pessoaFaleConosco.telefone" type="text" maxlength="11" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
                </div>
              </div>
              <div class="flex flex-col pb-4">
                <span class="text-gray-500 font-bold pb-3">Deixe sua mensagem</span>
                <textarea v-model="pessoaFaleConosco.mensagem" rows="3" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"></textarea>
              </div>
              <div class="flex items-center gap-4 pb-4">
                <button class="h-5 w-5 border border-2 border-zinc-700 flex items-center justify-center" @click="toggleButton">
                  <span class="text-zinc-700" v-if="showX">✔️</span>
                </button>
                <span class="text-gray-500">Clique para aceitar os <a href="/politica-de-privacidade" class="underline cursor-pointer">Termos de Uso e Política de Privacidade</a></span>
              </div>
              <div class="pb-12">
                <button @click="enviarEmail(1)" class="bg-[#F5333F] hover:bg-[#D53640] py-2.5 text-white px-4 rounded-full">Enviar Mensagem</button>
              </div>
            </div>
          </div>

          <!-- Quero Revender Formulário -->
          <div v-if="activeForm === 'queroRevender'">
            <h1 class="text-12 my-12" style="font-size: 32px;">Quer revender<br> <span style="color: #F5333F;">os produtos Inkor?</span></h1>
            <span>Entre em contato para se tornar um revendedor!</span>
            <QueroRevender></QueroRevender>
          </div>

          <!-- Trabalhe Conosco Formulário -->
          <div v-if="activeForm === 'trabalheConosco'">
            <h1 class="text-12 my-12" style="font-size: 32px;">Trabalhe<br> <span style="color: #F5333F;">conosco!</span></h1>
            <span>Quer fazer parte do nosso time Inkor? <br> Entre em contato com a gente!</span>
            <div id="form">
              <div class="flex flex-col py-4">
                <span class="text-gray-500 font-bold pb-3">Qual seu nome</span>
                <input v-model="pessoaTrabalheConosco.nome" type="text" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
              </div>
              <div class="flex flex-col pb-4">
                <span class="text-gray-500 font-bold pb-3">Informe seu e-mail</span>
                <input v-model="pessoaTrabalheConosco.email" type="email" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
              </div>
              <div class="flex md:flex-row flex-col md:gap-5">
                <div class="flex flex-col pb-4 w-full">
                  <span class="text-gray-500 font-bold pb-3">DDD + Telefone</span>
                  <input v-model="pessoaTrabalheConosco.telefone" type="text" maxlength="11" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
                </div>
              </div>
              <div class="flex md:flex-row flex-col md:gap-5">
                <div class="flex flex-col pb-4 w-full md:w-2/4">
                  <span class="text-gray-500 font-bold pb-3">Cidade</span>
                  <input v-model="pessoaTrabalheConosco.municipio" type="text" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
                </div>
                <div class="flex flex-col pb-4 w-full md:w-2/4">
                  <span class="text-gray-500 font-bold pb-3">Estado</span>
                  <input v-model="pessoaTrabalheConosco.estado" type="text" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500">
                </div>
              </div>
              <div class="flex flex-col pb-4">
                <span class="text-gray-500 font-bold pb-3">Deixe sua mensagem</span>
                <textarea v-model="pessoaTrabalheConosco.mensagem" rows="3" class="w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"></textarea>
              </div>
              <div class="flex flex-col pb-4">
                <span class="text-gray-500 font-bold pb-3">Anexe seu Currículo (Tamanho Máx: 2MB)</span>
                <input type="file" @change="handleFileChange" id="file-upload" class="hidden">
                <label for="file-upload" class="flex items-center px-4 py-2 text-gray-500 bg-gray-200 border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-300">Escolher arquivo</label>
                <span class="text-gray-500 mt-2">{{ fileName }}</span>
              </div>
              <div class="flex items-center gap-4 pb-4">
                <button class="h-5 w-5 border border-2 border-zinc-700 flex items-center justify-center" @click="toggleButton">
                  <span class="text-zinc-700" v-if="showX">✔️</span>
                </button>
                <span class="text-gray-500">Clique para aceitar os <a href="/politica-de-privacidade" class="underline cursor-pointer">Termos de Uso e Política de Privacidade</a></span>
              </div>
              <div class="pb-12">
                <button @click="enviarEmail(2)" class="bg-[#F5333F] hover:bg-[#D53640] py-2.5 text-white px-4 rounded-full">Enviar Mensagem</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Coluna de Botões -->
        <div class="w-full flex flex-col items-start md:items-center md:pt-20 text-gray-700 text-lg">
          <div class="flex flex-col gap-4 w-full md:w-auto">
            <button @click="showForm('faleConosco')" :class="['flex items-center rounded-lg text-left gap-3 px-4 py-3 w-full', activeForm === 'faleConosco' ? 'bg-[#F5333F] text-white' : 'bg-transparent border-b border-gray-300 text-gray-500 hover:text-gray-700']">
              <img src="../../img/fale-conosco-icon.svg" class="mb-2 translate-y-1" height="25">
              <span :class="activeForm === 'faleConosco' ? 'font-semibold' : ''">Fale Conosco</span>
            </button>

            <button @click="showForm('queroRevender')" :class="['flex items-center rounded-lg text-left gap-3 px-4 py-3 w-full', activeForm === 'queroRevender' ? 'bg-[#F5333F] text-white' : 'bg-transparent border-b border-gray-300 text-gray-500 hover:text-gray-700']">
              <img src="../../img/quero-revender-icon.svg" class="mb-2 translate-y-1" height="25">
              <span :class="activeForm === 'queroRevender' ? 'font-semibold' : ''">Quero Revender</span>
            </button>

            <button @click="showForm('trabalheConosco')" :class="['flex items-center rounded-lg text-left gap-3 px-4 py-3 w-full', activeForm === 'trabalheConosco' ? 'bg-[#F5333F] text-white' : 'bg-transparent border-b border-gray-300 text-gray-500 hover:text-gray-700']">
              <img src="../../img/trabalhe-conosco-icon.svg" class="mb-2 translate-y-1" height="25">
              <span :class="activeForm === 'trabalheConosco' ? 'font-semibold' : ''">Trabalhe Conosco</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import { VueRecaptcha } from "vue-recaptcha";
import QueroRevender from "@/components/quero-revender.vue";

export default {
  components: {
    VueRecaptcha,
    QueroRevender,
  },
  data() {
    return {
      showX: false,
      isVerified: false,
      verificationToken: null,
      siteKey: "6Lc8hfMfAAAAABIuU0hbG35FZH8WcreWG4qPe9It",
      email: "",
      nome: "",
      mensagem: "",
      estado: "",
      cidade: "",
      telefone: "",
      departamento: "Selecione um Departamento",
      activeForm: "faleConosco",
      fileName: "",
      pessoaFaleConosco: {
        nome: "",
        email: "",
        telefone: "",
        mensagem: "",
      },
      pessoaTrabalheConosco: {
        nome: "",
        email: "",
        telefone: "",
        curriculo: null,
        municipio: "",
        estado: "",
        mensagem: "",
      },
    };
  },
  methods: {
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async enviarEmail(motivo) {
      if (motivo == 1) {
        if (!this.pessoaFaleConosco.nome) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, informe seu nome!",
            type: 'error',
          });
        } else if (!this.pessoaFaleConosco.email || !this.isValidEmail(this.pessoaFaleConosco.email)) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, informe um email válido!",
            type: 'error',
          });
        } else if (!this.pessoaFaleConosco.mensagem) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, informe sua mensagem!",
            type: 'error',
          });
        } else if (!this.showX) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, aceite nossos termos e políticas!",
            type: 'error',
          });
        } else {
          const params = {
            nome: this.pessoaFaleConosco.nome,
            email: this.pessoaFaleConosco.email,
            telefone: this.pessoaFaleConosco.telefone,
            mensagem: this.pessoaFaleConosco.mensagem,
            motivo: motivo
          }

          try {
            const response = await axios.post('/api/contato/email/fale-conosco', params);
            if (response.data.mensagem == "Email enviado com sucesso") {
              this.$notify({
                title: "Email enviado!",
                text: "Agradecemos pelo contato!",
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
      if (motivo == 2) {
        if (!this.pessoaTrabalheConosco.nome) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, informe seu nome!",
            type: 'error',
          });
        } else if (!this.pessoaTrabalheConosco.email || !this.isValidEmail(this.pessoaTrabalheConosco.email)) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, informe um email válido!",
            type: 'error',
          });
        } else if (!this.pessoaTrabalheConosco.mensagem && !this.pessoaTrabalheConosco.curriculo) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, nos mande uma mensagem ou anexe seu currículo!",
            type: 'error',
          });
        } else if (!this.showX) {
          this.$notify({
            title: "Ops!",
            text: "Por favor, aceite nossos termos e políticas!",
            type: 'error',
          });
        } else {
          const formData = new FormData();
          formData.append('nome', this.pessoaTrabalheConosco.nome);
          formData.append('email', this.pessoaTrabalheConosco.email);
          formData.append('telefone', this.pessoaTrabalheConosco.telefone);
          formData.append('mensagem', this.pessoaTrabalheConosco.mensagem);
          formData.append('motivo', motivo);
          formData.append('curriculo', this.pessoaTrabalheConosco.curriculo);

          try {
            const response = await axios.post('/api/contato/email/fale-conosco', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            if (response.data.mensagem == "Email enviado com sucesso") {
              this.$notify({
                title: "Email enviado!",
                text: "Agradecemos pelo contato!",
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    toggleButton() {
      this.showX = !this.showX;
    },
    onVerify(response) {
      this.isVerified = true;
      this.verificationToken = response;
    },
    onExpired() {
      this.isVerified = false;
      this.verificationToken = null;
    },
    showForm(formName) {
      this.activeForm = formName;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.pessoaTrabalheConosco.curriculo = file;
        this.fileName = file.name;
      } else {
        this.pessoaTrabalheConosco.curriculo = null;
        this.fileName = '';
      }
    },
    validateFileSize(event) {
      const file = event.target.files[0];
      if (file && file.size > 2 * 1024 * 1024) {
        alert('O arquivo deve ter no máximo 2MB.');
        this.fileName = '';
        event.target.value = '';
      }
    }
  }
};
</script>

<style>
.bg-inkor {
  background-image: url('../../img/fale-com-a-inkor-banner.png');
  background-size: cover;
  background-position: center;
  position: relative;
}
</style>
