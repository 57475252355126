<template>
    <div class="flex justify-center bg-[#EDEDED] h-auto text-center p-4">
        <div>
            <span>*As cores deste site são apenas referências, podendo existir
                divergências entre as cores apresentadas na
                tela e as cores reais dos produtos comercializados.</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AvisoFooter',
}
</script>