<template>
  <div class="col-12 py-1 bg-colors-default"></div>
</template>

<style scoped>
.bg-colors-default {
  background-image: linear-gradient(to right, var(--red-dark), var(--red-dark)),
    linear-gradient(to right, var(--red), var(--red)), linear-gradient(to right, var(--red-light), var(--red-light)),
    linear-gradient(to right, var(--orange), var(--orange)),
    linear-gradient(to right, var(--yellow-dark), var(--yellow-dark)),
    linear-gradient(to right, var(--yellow), var(--yellow));
  background-repeat: no-repeat;
  background-position: 0 17%, 20% 33.33%, 40% 66.66%, 60% 84%, 80% 85%, 100% 100%;
  background-size: 16.67%, 16.67%, 16.67%, 16.68%, 16.67%;
}
</style>
