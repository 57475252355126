<template>
  <section class="relative flex justify-center m-0 h-[525px] p-0" id="banner-home">
    <div class="absolute inset-0 bg-red-banner"></div>
    <div class="absolute inset-0 bg-banner rounded-b-[30px]"></div>

    <div v-if="show" class="relative flex px-10 items-center md:h-[438px] rounded-b-[30px] z-10">
      <div @click="previousSlide"
        class="md:fixed md:left-16 md:top-[8cm] hidden transform -translate-y-1/2 flex items-center justify-center text-white w-20 h-auto p-4 rounded-full bg-white/50 shadow-sm cursor-pointer">
        <img src="../../img/arrow-left.svg" alt="">
      </div>
      <div class="md:w-screen md:px-24">
        <div ref="slider"
          class="flex justify-center md:justify-between items-center w-[210px] md:w-36 h-128 rounded-lg py-2">
          <div v-for="(slide, index) in slides" :key="index" class="slide rounded-3xl">
            <div class="flex mt-10 md:mb-12 mb-48 w-full justify-center">
              <a :href="slide.link" class="md:flex md:justify-between gap-x-10 items-center">
                <img :src="slide.imagem" :alt="slide.title" class="items-center w-auto h-48 md:h-96" />
                <div class="inset-0 flex flex-col justify-center text-white">
                  <h2 class="text-3xl font-bold md:text-left">{{ slide.title }}</h2>
                  <p class="mt-2 w-64">{{ slide.subtitle }}</p>
                  <div class="flex h-10 mt-2 w-44 justify-center items-center bg-white rounded-sm">
                    <span class="text-blue-950 flex items-center justify-center">Saiba mais</span>
                    <div class="ml-2 md:block flex items-center justify-center w-[0.5px] text-blue-950">|</div>
                    <img class="ml-4 flex justify-center items-center md:block h-2" src="../../img/arrow.svg" alt="" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div @click="nextSlide"
        class="md:fixed md:right-16 md:top-[8cm] hidden transform -translate-y-1/2 flex items-center justify-center text-white w-20 h-auto p-4 rounded-full bg-white/50 shadow-sm cursor-pointer">
        <img src="../../img/arrow-right.svg" alt="">
      </div>
    </div>
  </section>
</template>


<script>
import { tns } from "tiny-slider";

export default {
  name: "BannerHome",
  data() {
    return {
      banners: [],
      slides: [],
      slider: null,
      show: false,
    };
  },
  methods: {
    async getBanners() {
      try {
        const response = await axios.get("/api/banners");
        this.banners = response.data;
        this.show = true;
        this.populateSlides();
      } catch (error) {
        console.error(error);
      }
    },
    populateSlides() {
      this.slides = this.banners.map(banner => ({
        title: banner.title,
        subtitle: banner.subtitle,
        imagem: banner.imagem,
        link: banner.link
      }));
    },
    nextSlide() {
      this.slider.goTo("next");
    },
    previousSlide() {
      this.slider.goTo("prev");
    },
    initSlider() {
      this.slider = tns({
        container: this.$refs.slider,
        items: 1,
        slideBy: "page",
        autoplay: true,
        autoplayTimeout: 5000,
        controls: false,
        nav: false,
        loop: true,
        speed: 2000,
        mouseDrag: true,
      });
    }
  },
  async mounted() {
    await this.getBanners();
    this.initSlider();
  }
};
</script>

<style scoped>
#banner-home {
  position: relative;
  overflow: hidden;
}

.bg-red-banner {
  position: absolute;
  inset: 0;
  background: url('../../img/banner-vermelho-home.png') no-repeat center center;
  background-size: cover;
  z-index: 0;
}

.bg-banner {
  position: absolute;
  bottom: 10%;
  left: 3%;
  right: 3%;
  background-image: url('../../img/banner-home.png');
  background-size: cover;
  background-position: center;
  z-index: 1;
  box-sizing: border-box;
}

.slide {
  position: relative;
  z-index: 2;
}
</style>
