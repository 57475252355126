<template>
  <section class="mt-5 mb-5" id="breadcrumb">
    <div class="container">
      <a href="/">home</a>
      &gt;
      <a href="/simulador-tintas">simulador de cores</a>
    </div>
  </section>
  <section id="sobre-page" class="mt-5">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3">
          <h3 class="bold">Simulador de cores</h3>
        </div>
      </div>
      <div class="row">
        <!-- Mostra a simulação -->
        <div class="col-12 col-sm-8 mb-1 tab-content" id="pills-tabContent">
          <div v-for="(tab, index) in imagens" :key="index" class="tab-pane fade"
            :class="{ show: index === 0, active: index === 0 }" :id="tab.title" role="tabpanel" tabindex="0"
            :style="myStyle">
            <img :src="tab.imagem" class="img-fluid" />
          </div>
        </div>
        <!-- Lista de cores -->
        <div class="col-12 col-sm-4 mb-5">
          <div class="row pe-3 py-3 animate__animated animate__fadeInUp">
            <div v-for="cor in paletaCores" :key="cor.nome" class="col-3 col-sm-4 pe-0 mb-2">
              <a href="#void" @click="changeColor(cor)" data-bs-toggle="tooltip" data-bs-placement="bottom"
                :title="cor.nome">
                <div class="square-color" :style="{ backgroundColor: cor.hexadecimal }"></div>
              </a>
              <small class="text-center">{{ cor.nome }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <nav class="nav nav-pills flex-column flex-sm-row" id="list-bt-simulador" role="tablist">
            <a v-for="(tab, index) in imagens" :key="index" class="flex-sm-fill text-sm-center "
              :class="{
                active: index === 0,
                'bg-[#011E41]': index === 0,
                'bg-[#1A3554]': index === 1,
                'bg-[#344B67]': index === 2,
                'bg-[#4D627A]': index === 3,
                'bg-[#67788D]': index === 4,
                brl: index === 0,
                nbra: index === 1 || index === 2 || index === 3,
                brr: index === 4,
              }"
              :href="'#' + tab.title" data-bs-toggle="pill" :data-bs-target="'#' + tab.title" role="tab">
              {{ tab.title }}
            </a>
          </nav>
        </div>
      </div>

      <Socials />

      <LastPosts class="mt-5" :limit="2" />
    </div>
  </section>
</template>

<script>
import TintaBanheiro from "../../img/tinta-banheiro.png";
import TintaCozinha from "../../img/tinta-cozinha.png";
import TintaFachada from "../../img/tinta-fachada.png";
import TintaQuarto from "../../img/tinta-quarto.png";
import TintaSala from "../../img/tinta-sala.png";

export default {
  data() {
    return {
      imagens: [
        { title: "Banheiro", imagem: TintaBanheiro },
        { title: "Cozinha", imagem: TintaCozinha },
        { title: "Fachada", imagem: TintaFachada },
        { title: "Quarto", imagem: TintaQuarto },
        { title: "Sala", imagem: TintaSala },
      ],
      myStyle: { backgroundColor: "#a82a3b" },
      paletaCores: [],
    };
  },
  async created() {
    try {
      const payload = { params: { flag_tintas: 1 } };
      this.paletaCores = (await axios.get("/api/simulador-ambiente-cores", payload)).data;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    changeColor(color) {
      this.myStyle.backgroundColor = color.hexadecimal;
    },
  },
};
</script>

<style scoped>
/* Add custom styles for mobile responsiveness if needed */
@media (max-width: 768px) {
  .justify-center {
    flex-direction: column;
  }
}
</style>
