<template>
  <div class="bg-inkor relative py-12">
    <div class="flex flex-col">
      <div class="flex flex-col text-white text-center items-center custom-line-height mb-12">
        <span class="text-[36px]" style="font-weight: 700;">Acesse nossos serviços</span>
        <span class="text-[20px] max-w-[466px] mt-2">Estamos presentes em várias partes do Brasil em locais
          estratégicos para atender você.</span>
      </div>
      <div class="flex flex-wrap justify-center items-center gap-x-10 z-10 relative">
        <a href="/calculadora">
          <div
            class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] lg:h-[351px] lg:w-[215px] hover:scale-105 transition duration-300 transform rounded-2xl mt-4 px-3">
            <div class="flex md:flex-col justify-center items-center h-full gap-2">
              <img src="../../img/calculadora-icon.svg" alt="">
              <div class="flex flex-col">
                <span class="text-[16px] font-bold text-center">Calculadora de materiais</span>
                <span class="text-[16px] text-center">Calcule a quantidade de material necessário para sua
                  obra.</span>
              </div>
            </div>
          </div>
        </a>
        <a href="/downloads">
          <div
            class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] lg:h-[351px] lg:w-[215px] hover:scale-105 transition duration-300 transform rounded-2xl mt-4 px-3">
            <div class="flex md:flex-col justify-center items-center h-full gap-2">
              <img src="../../img/download-icon.svg" alt="">
              <div class="flex flex-col">
                <span class="text-[16px] font-bold text-center">Downloads</span>
                <span class="text-[16px] text-center">Conheça todo o nosso portfólio de produtos e
                  soluções.</span>
              </div>
            </div>
          </div>
        </a>
        <!-- <div
                    class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] lg:h-[351px] lg:w-[215px] rounded-2xl mt-4 px-3">
                    <div class="flex md:flex-col justify-center items-center h-full gap-2">
                        <img src="../../img/assistencia-icon.svg" alt="">
                        <div class="flex flex-col">
                            <span class="text-[16px] font-bold text-center">Assistência técnica</span>
                            <span class="text-[16px] text-center">Seu canal Inkor para reparos, reposições e atendimento
                                técnico.</span>
                        </div>
                    </div>
                </div> -->
        <a href="/contato">
          <div
            class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] lg:h-[351px] lg:w-[215px] hover:scale-105 transition duration-300 transform rounded-2xl mt-4 px-3">
            <div class="flex md:flex-col justify-center items-center h-full gap-2">
              <img src="../../img/sac-icon.svg" alt="">
              <div class="flex flex-col">
                <span class="text-[16px] font-bold text-center">Central de relacionamento (SAC)</span>
                <span class="text-[16px] text-center">Atendimento personalizado para sanar todas as suas
                  dúvidas.</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>


  <!-- <section class="bg-inkor relative px-3 md:px-0 h-[750px] rounded-b-[30px]">
        <div class="absolute inset-0 flex flex-col justify-center items-center rounded-b-[30px]">
            <div class="flex flex-col">
                <div class="flex flex-col text-white text-center items-center custom-line-height mb-12">
                    <span class="text-[36px]" style="font-weight: 700;">Acesse nossos serviços</span>
                    <span class="text-[20px] max-w-[466px] mt-2">Estamos presentes em várias partes do Brasil em locais estratégicos para atender você.</span>
                </div>
                <div class="flex md:grid md:grid-cols-2 lg:flex lg:flex-row grid-col flex-col gap-2 justify-center items-center z-10 relative">
                    <div class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] rounded-2xl mt-4 px-3">
                        <div class="flex md:flex-col justify-center items-center h-full gap-2">
                            <img src="../../img/calculadora-icon.svg" alt="">
                            <div class="flex flex-col">
                                <span class="text-[16px] font-bold text-center">Calculadora de material</span>
                                <span class="text-[16px] text-center">Calcule a quantidade de espaçadores necessária para sua obra.</span>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] rounded-2xl mt-4 px-3">
                        <div class="flex md:flex-col justify-center items-center h-full gap-2">
                            <img src="../../img/download-icon.svg" alt="">
                            <div class="flex flex-col">
                                <span class="text-[16px] font-bold text-center">Downloads</span>
                                <span class="text-[16px] text-center">Conheça todo o nosso portfólio de produtos e soluções.</span>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] rounded-2xl mt-4 px-3">
                        <div class="flex md:flex-col justify-center items-center h-full gap-2">
                            <img src="../../img/assistencia-icon.svg" alt="">
                            <div class="flex flex-col">
                                <span class="text-[16px] font-bold text-center">Assistência técnica</span>
                                <span class="text-[16px] text-center">Seu canal Inkor para reparos, reposições e atendimento técnico.</span>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white cursor-pointer h-[146px] w-[325px] md:h-[351px] md:w-[215px] rounded-2xl mt-4 px-3">
                        <div class="flex md:flex-col justify-center items-center h-full gap-2">
                            <img src="../../img/sac-icon.svg" alt="">
                            <div class="flex flex-col">
                                <span class="text-[16px] font-bold text-center">Central de relacionamento (SAC)</span>
                                <span class="text-[16px] text-center">Atendimento personalizado para sanar todas as suas dúvidas.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</template>

<script>
export default {
  name: "NossosServicos"
}
</script>

<style>
.bg-inkor {
  background-image: url('../../img/banner-nossos-servicos.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* border-bottom-left-radius: 30px; */
  /* border-bottom-right-radius: 30px; */
}

.text-white {
  z-index: 1;
  /* Ensure text is above the overlay */
}

.bg-inkor::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 35, 77, 0.8);
  /* Ensure overlay covers entire section */
  z-index: 0;
  border-radius: inherit;
  /* To match the border-radius of the section */
}
</style>
