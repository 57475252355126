<template>
    <div class="p-2 md:my-10">
        <div class="bg-black/30 overflow-visible relative" style="height: 621px;">
            <div class="container max-w-[1200px] py-12 h-full">
                <div class="grid md:grid-cols-2 grid-cols-1 h-full">
                    <div class="md:px-0 px-2 flex flex-col md:justify-center p-0">
                        <h1 class="text-[36px] md:text-[48px] font-semibold text-white">Estamos cada vez <br> mais
                            pertinho de você</h1>
                        <div class="mt-2">
                            <span class="text-white text-[16px] md:text-[20px] hidden md:block">Estamos presentes em
                                várias partes do Brasil em <br> locais estratégicos para atender você.</span>
                            <span class="text-white text-[16px] md:text-[20px] block md:hidden">Estamos presentes em
                                várias partes do Brasil locais estratégicos para atender você.</span>
                        </div>
                    </div>
                    <div class="relative flex justify-center items-center">
                        <img src="../../img/mapa-filiais.svg" alt="" class="absolute w-[150%] h-auto z-10"
                            style="top: 50%; transform: translateY(-50%);">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapaFiliais',
}
</script>
