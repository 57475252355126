<template>
  <section
    class="mt-5 mb-5"
    id="breadcrumb"
  >
    <div class="container">
      <a href="/">home</a>
      &gt;
      <a href="/contato/especialista">contato especialista</a>
    </div>
  </section>
  <section
    id="sobre-page"
    class="mt-5"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3">
          <h3 class="bold">Envie sua dúvida ao nosso especialista</h3>
          <p>
            Envie sua dúvida para
            <br />
            o especialista Inkor.
          </p>
        </div>
        <div class="col-sm-6">
          <div
            :style="show"
            class="alert alert-success alert-dismissible fade show text-center"
          >
            Mensagem enviada com sucesso!
            <br />
            Em breve lhe retornaremos.
          </div>
          <div
            method="post"
            class="form-contato"
            id="form-fale-representante"
          >
            <div class="row">
              <div class="col-sm-12 px-3 py-3">
                <div
                  class="col-sm-12 text-center"
                  style="display: none"
                  id="msg-retorno"
                >
                  <div class="alert col-sm-12 text-center animate__animated mb-0">
                    <h6 class="text-center mb-0"></h6>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="input-group mb-3">
                  <span
                    class="input-group-text"
                    id="basic-addon1"
                  >
                    <img src="../../img/contato-nome.svg" />
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    name="in_nome"
                    v-model="nome"
                  />
                </div>
              </div>

              <div class="col">
                <div class="input-group mb-3">
                  <span
                    class="input-group-text"
                    id="basic-addon1"
                  >
                    <img src="../../img/contato-email.svg" />
                  </span>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    placeholder="E-mail"
                    name="in_email"
                  />
                </div>
              </div>

              <div class="col-sm-12 mb-4">
                <div class="input-group">
                  <textarea
                    v-model="mensagem"
                    class="form-control"
                    rows="5"
                    name="in_mensagem"
                  ></textarea>
                </div>
              </div>

              <vue-recaptcha
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="siteKey"
                class="ml-3"
              />

              <div class="col-sm-12">
                <button
                  v-if="isVerified"
                  @click="enviar"
                  id="bt-submit"
                  class="bg-red -white"
                >
                  <img
                    src="../../img/contato-enviar.svg"
                    class="me-3"
                  />
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 ps-5">
          <Socials />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      isVerified: false,
      verificationToken: null,
      siteKey: "6Lc8hfMfAAAAABIuU0hbG35FZH8WcreWG4qPe9It",
      email: "",
      nome: "",
      mensagem: "",
      show: "display: none",
    };
  },
  methods: {
    async enviar() {
      try {
        const payload = {
          nome: this.nome,
          email: this.email,
          mensagem: this.mensagem,
          contato: "especialista",
          motivo: "0",
          recaptcha: this.verificationToken,
        };
        const response = await axios.post("/api/contato/email", payload);
        if (response.status == 200) {
          this.nome = "";
          this.email = "";
          this.mensagem = "";
          this.show = "display: block";
          window.dataLayer.push({ event: "tag-submit-especialista", "gtm.elementId": "tag-submit-especialista" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onVerify(response) {
      this.isVerified = true;
      this.verificationToken = response;
    },
    onExpired() {
      this.isVerified = false;
      this.verificationToken = null;
    },
  },
};
</script>
