<template>
  <div class="row">
    <div v-for="value in blog.data" :key="value.id" class="col-6 py-3 d-flex align-items-center">
      <div class="col-sm-4 pe-2">
        <router-link :to="{ name: 'blog.artigo', params: { id: value.id } }">
          <img v-if="value.image" :src="value.image" class="img-fluid" />
          <img v-else src="../../img/tinta.jpg" class="img-fluid" />
        </router-link>
      </div>
      <div class="col-sm-8 pt-2">
        <router-link :to="{ name: 'blog.artigo', params: { id: value.id } }">
          <h6 class="bold">{{ value.titulo }}</h6>
          <p>{{ value.descricao }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      blog: {},
    };
  },
  props: {
    limit: {
      type: Number,
      default: 6,
    },
  },
  async created() {
    await this.loadBlog();
  },
  methods: {
    async loadBlog() {
      const payload = { params: { page: 1, per_page: 6 } };
      try {
        this.blog = (await axios.get("/api/blog", payload)).data;
        this.blog.data = this.blog.data.slice(0, this.limit);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
