// Import necessary modules
import "./bootstrap.js";
import { createApp } from "vue/dist/vue.esm-bundler.js";
import Notifications from "@kyvg/vue3-notification";
import router from "./router";
// Import components
import AppComponent from "./App.vue";
import ColoredBar from "./components/ColoredBar.vue";
import LastPosts from "./components/LastPosts.vue";
import Pagination from "./components/Pagination.vue";
import Slider from "./components/Slider.vue";
import Socials from "./components/Socials.vue";
// Import VueGoogleMaps plugin
import VueGoogleMaps from "@fawmi/vue-google-maps";
const app = createApp({ components: { AppComponent } }).use(router).use(Notifications);
// Register components
app.component("ColoredBar", ColoredBar);
app.component("LastPosts", LastPosts);
app.component("Pagination", Pagination);
app.component("Slider", Slider);
app.component("Socials", Socials);
// Use VueGoogleMaps plugin
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCTl3Tc8elFaJFFokwUGV8PnE8UiYc0Ak0",
  },
});
// Mount the app on the DOM
app.mount("#app");
