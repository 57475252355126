<template>
  <div class="row">
    <div class="col-sm text-sm-start text-center pt-3">
      <div class="text-muted d-inline-block">Por Página: &nbsp;</div>
      <select v-model="paginator.per_page" class="form-control d-inline-block w-auto" @change="setPage(1)">
        <option v-for="opt in options" :key="opt" :value="opt">
          {{ opt }}
        </option>
      </select>
    </div>
    <div class="col-sm text-sm-center text-center pt-3">
      <span class="text-muted">Páginas {{ paginator.current_page }} de {{ paginator.last_page }}</span>
    </div>
    <div class="col-sm pt-3">
      <nav v-if="hasMultiplePages" aria-label="Navegação de página">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: paginator.current_page === 1 }">
            <a class="page-link" @click="setPage(1)">
              «
            </a>
          </li>
          <li class="page-item" :class="{ disabled: paginator.current_page === 1 }">
            <a class="page-link" @click="setPage(paginator.current_page - 1)">
              ‹
            </a>
          </li>
          <li v-for="page in pages" :key="page" class="page-item page-number"
            :class="{ active: paginator.current_page === page }">
            <a class="page-link" @click="setPage(page)">
              {{ page }}
            </a>
          </li>
          <li class="page-item" :class="{ disabled: paginator.current_page === paginator.last_page }">
            <a class="page-link" @click="setPage(paginator.current_page + 1)">
              ›
            </a>
          </li>
          <li class="page-item" :class="{ disabled: paginator.current_page === paginator.last_page }">
            <a class="page-link" @click="setPage(paginator.last_page)">
              »
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxPages: {
      type: Number,
      default: 5,
    },
    paginator: {
      type: Object,
      validator: (value) => value.current_page && value.last_page,
    },
    options: {
      type: Array,
      default: () => [10, 20, 30],
    },
  },
  methods: {
    setPage(page) {
      if (page !== this.paginator.current_page) {
        this.$emit("changePage", page);
      }
    },
  },
  computed: {
    hasMultiplePages() {
      return this.paginator && this.paginator.last_page > 1;
    },
    pages() {
      let startPage, endPage;
      const maxPages = Math.min(this.maxPages, this.paginator.last_page);
      const maxPagesBefore = Math.floor(maxPages / 2);
      const maxPagesAfter = Math.ceil(maxPages / 2) - 1;
      if (this.paginator.current_page <= maxPagesBefore) {
        startPage = 1;
        endPage = maxPages;
      } else if (this.paginator.current_page + maxPagesAfter >= this.paginator.last_page) {
        startPage = this.paginator.last_page - maxPages + 1;
        endPage = this.paginator.last_page;
      } else {
        startPage = this.paginator.current_page - maxPagesBefore;
        endPage = this.paginator.current_page + maxPagesAfter;
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
    },
  },
};
</script>

<style scoped>
.page-item {
  cursor: default;
}

.page-item:not(.disabled, .active) {
  cursor: pointer;
}
</style>
